import React, { useCallback } from 'react';
import QrReader from 'react-qr-reader';

import FlashLight from '../FlashLight/FlashLight';
import './QRCodeReader.scss';

try {
	const vw = Math.max(
		document.documentElement.clientWidth || 0,
		window.innerWidth || 0
	);
	const vh = Math.max(
		document.documentElement.clientHeight || 0,
		window.innerHeight || 0
	);
	const root = document.documentElement;

	const borderLeftRight = vw / 4;
	const borderTopBottom = (vh - 2 * borderLeftRight - 60) / 2;

	root.style.setProperty('--border-top-bottom', borderTopBottom + 'px');
	root.style.setProperty('--border-right-left', borderLeftRight + 'px');
} catch (e) {
	console.error(e);
}

const MemoizedQrCode = React.memo(
	({ onScan }) => {
		return (
			<QrReader
				delay={300}
				style={{ width: '100%' }}
				onScan={onScan}
				onError={(exception) => console.error(exception)}
			/>
		);
	},
	() => true
);
function QRCodeReader({ onCodeDetected, pending, found, error }) {
	const qrCodeSuccessCallback = useCallback(
		(message) => {
			if (message) {
				onCodeDetected(message);
			}
		},
		[onCodeDetected]
	);

	const QrCodeScannerStatus = error
		? 'errorCode'
		: pending
			? 'pending'
			: found
				? 'found'
				: 'notfound';
	return (
		<div className={`QRCode_container ${QrCodeScannerStatus}`}>
			<div>
				<FlashLight />
				<MemoizedQrCode onScan={qrCodeSuccessCallback} />
				{!error && !found && !pending && (
					<div className="scannerComplementoryInfo">Scannner le code</div>
				)}
				{!error && !found && pending && (
					<div className="scannerComplementoryInfo">Loading</div>
				)}
				{error && !found && !pending && (
					<div className="scannerComplementoryInfo">
						<span>Capteur introuvable</span>
					</div>
				)}
			</div>
		</div>
	);
}

export default React.memo(QRCodeReader, (prevProps, nextProps) => {
	return (
		prevProps.pending === nextProps.pending &&
		prevProps.found === nextProps.found &&
		prevProps.error === nextProps.error
	);
});
