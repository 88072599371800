import React from 'react';
import { useHistory } from 'react-router';

import { Cover } from '../../../components/Animation/Animation';
import WizardFooter from '../../../components/Footer/WizardFooter';
import RetryButton from '../../../components/RetryButton/RetryButton';
import WizardStepper from '../../../components/WizardStepper/WizardStepper';

const getErrorMessageBasedOnError = (error: { name: string }) => {
	if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
		return "Malgré l'autorisation d'utiliser votre caméra, le navigateur rencontre des difficultés à l'utiliser. Merci de vous assurer que votre matériel n'est pas utilisé par une autre application.";
	} else if (
		error.name === 'NotAllowedError' ||
		error.name === 'PermissionDeniedError'
	) {
		return "Votre caméra ne semble pas disponible. Vérifiez que votre navigateur a les droits d'accès à votre caméra. Sinon, cliquez sur suivant.";
	} else {
		return "L'application rencontre une erreur avec votre caméra.";
	}
};
type PhotoErrorTypes = {
	error: { name: string };
	onRetry: () => void;
};
export const PhotoError = ({ error, onRetry }: Readonly<PhotoErrorTypes>) => {
	const history = useHistory();

	return (
		<div id="PhotoOff" className="page wizard-page">
			<Cover path="/images/big/take_picture_error" />

			<div className="wizard-page__content">
				<h2>Prendre une photo</h2>
				<p>{getErrorMessageBasedOnError(error)}</p>
				<RetryButton onClick={onRetry} />
			</div>
			<WizardStepper activeIndex={6} />
			<WizardFooter
				previous={() => {
					history.push('/wizard/measure?measure=indoor');
				}}
				next={() => {
					history.goBack();
				}}
			/>
		</div>
	);
};
