export const host = process.env.REACT_APP_DATA_API;

/**
 * List of token used for tracking the wizard
 */
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const END_CONTAINER_ACTIVE_CONTRACT = 'END_CONTAINER_ACTIVE_CONTRACT';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const CREATE_CONTAINER = 'CREATE_CONTAINER';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const TAG_CONTAINER = 'TAG_CONTAINER';
export const DELETE_TAG_CONTAINER = 'DELETE_TAG_CONTAINER';
export const TAG_SENSOR = 'TAG_SENSOR';
export const UPDATE_SENSOR_ORGANIZATION = 'UPDATE_SENSOR_ORGANIZATION';
