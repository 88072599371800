import { useCallback, useState } from 'react';

import { createLogger } from '../../monitoring/logrocket';

const logger = createLogger('useFlash hook');

function isIOS() {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

const videoConstraints = { facingMode: 'environment' };

export const isFlashSupported = (): Promise<boolean> => {
	if (isIOS()) {
		return Promise.resolve(false);
	}
	if (!navigator.mediaDevices) {
		return Promise.resolve(false);
	}

	return new Promise((resolve) => {
		navigator.mediaDevices
			.getUserMedia({
				video: videoConstraints
			})
			.then(async function (stream) {
				logger.log('getUserMedia done');

				if (stream) {
					const [track] = stream.getTracks();

					if (track instanceof MediaStreamTrack) {
						logger.log('Manipulating track');
						try {
							// ImageCapture is a new specification supported in modern browsers
							// @ts-ignore
							const imageCapture = new ImageCapture(track);
							const capabilities = await imageCapture.getPhotoCapabilities();

							logger.log('Capabilities', JSON.stringify(capabilities));

							if (capabilities?.fillLightMode?.includes('flash')) {
								resolve(true);
							}
						} catch (err) {
							resolve(false);
						}
					}
				}

				resolve(true);
			});
	});
};
export default function useFlash(): [boolean, () => void] {
	const [flash, setFlash] = useState(false);

	const toggle = useCallback(() => {
		logger.log('Toggle the Flash');

		if (!navigator.mediaDevices) {
			logger.log('mediaDevices not supported');
			setFlash(!flash);
		} else {
			logger.log('mediaDevices supported');

			navigator.mediaDevices
				.getUserMedia({
					video: videoConstraints
				})
				.then(async function (stream) {
					logger.log('getUserMedia done');

					if (stream) {
						const [track] = stream.getTracks();

						if (track instanceof MediaStreamTrack) {
							logger.log('Manipulating track');
							try {
								// ImageCapture is a new specification supported in modern browsers
								// @ts-ignore
								const imageCapture = new ImageCapture(track);
								const capabilities = await imageCapture.getPhotoCapabilities();

								logger.log('Capabilities', JSON.stringify(capabilities));

								if (capabilities?.fillLightMode?.includes('flash')) {
									await track.applyConstraints({
										advanced: [{ torch: !flash } as any]
									});
								}
								setFlash(!flash);
							} catch (err) {
								return err;
							}
						}
					}
				});
		}
	}, [flash]);

	return [flash, toggle];
}
