import React from 'react';

import { Option } from '../../../../../api/model';
import { Select } from '../../../../../components/select';
import { statusOptions } from './statusOptions';

type StatusSelectTypes = {
	value?: Option;
	onChange: (value: Option) => void;
};

export const StatusSelect = ({
	value,
	onChange
}: Readonly<StatusSelectTypes>) => {
	return (
		<Select
			placeholder="Statut"
			options={statusOptions}
			value={value}
			onChange={(value: unknown) => onChange(value as Option)}
		></Select>
	);
};
