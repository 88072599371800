import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import './SignalStrength.scss';
import { isDefined } from '../../lib/Helper/ObjectHelper';

type SignalStrengthProps = {
	signalStrength: number;
	rssi?: string | null;
	snr?: string | null;
};

export const SignalStrength = ({
	signalStrength,
	rssi,
	snr
}: SignalStrengthProps) => {
	const signalStrengthText = !isDefined(signalStrength)
		? 'Inconnu'
		: signalStrength < 2
			? 'Faible'
			: signalStrength === 2
				? 'Moyen'
				: 'Fort';

	return (
		<p className="signal-strength">
			<Tippy content={`RSSI: ${rssi || 'Inconnu'}, SNR: ${snr || 'Inconnu'}`}>
				<button type="button">
					<img
						className="signal-strength--img"
						src="/images/wifi-signal.svg"
						alt=""
					/>
					<span id="signalStrength">{signalStrengthText}</span>
				</button>
			</Tippy>
		</p>
	);
};
