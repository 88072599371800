// REACT
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

//COMPONENTS
import { ApiGetContainer } from '../../api/model/container';
// CONTEXT/API
import { useSensorContext } from '../../components/Context/Context';
import { createLogger } from '../../monitoring/logrocket';
import { GenericGeolocationMap } from './Geolocation/GenericGeolocationMap';
import { GeolocCommentModal } from './Geolocation/GeolocCommentModal';

const logger = createLogger('Geolocalisation Page');

const SensorGeolocationMap = () => {
	const sensorContext = useSensorContext();
	const history = useHistory();
	const [showAddressCommentModal, setShowAddressCommentModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const defaultAddressCommentValue =
		sensorContext.sensor.container?.addressComment;

	return (
		<GenericGeolocationMap
			onCancel={() => history.goBack()}
			onKeepCoordinates={(container: ApiGetContainer) => {
				const sensor = {
					...sensorContext.sensor,
					coords: {
						lat: container?.latitude,
						lng: container?.longitude
					},
					keepSensorCoordinates: true
				};
				logger.log('Keep coordinates', JSON.stringify(sensor));

				setLoading(true);
				sensorContext.setSensor(sensor);
				setShowAddressCommentModal(true);
				setLoading(false);
			}}
			onUpdateCoordinates={(coords: { lat: number; lng: number }) => {
				sensorContext.setSensor({
					...sensorContext.sensor,
					coords: coords
				});
				setShowAddressCommentModal(true);
			}}
			container={sensorContext.sensor.container}
		>
			{showAddressCommentModal ? (
				<GeolocCommentModal
					defaultValue={defaultAddressCommentValue ?? ''}
					onValidate={(comment) => {
						sensorContext.setSensor({
							...sensorContext.sensor,
							container: {
								...sensorContext.sensor.container,
								addressComment: comment
							}
						});
					}}
					onCancel={() => setShowAddressCommentModal(false)}
					loading={loading}
				/>
			) : (
				<></>
			)}
		</GenericGeolocationMap>
	);
};

export default SensorGeolocationMap;
