import { ComponentPropsWithoutRef } from 'react';

import './PhotoWrapper.scss';

export const PhotoWrapper = ({
	children,
	...props
}: Readonly<ComponentPropsWithoutRef<'div'>>) => {
	return (
		<div {...props} id="PhotoOn">
			{children}
		</div>
	);
};
