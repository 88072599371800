import React, { ReactElement } from 'react';

import { classWithModifiers } from '../../lib/utils/css';

type IconProps = {
	size?: number;
	iconPath: ReactElement;
	className?: string;
	fill?: string;
	classModifiers?: string[];
};
const Icon = ({
	size = 16,
	iconPath,
	className,
	classModifiers,
	fill
}: IconProps) => {
	const styles = {
		svg: {
			display: 'inline-block',
			verticalAlign: 'middle',
			fill: ''
		}
	};

	if (!!fill) {
		styles.svg.fill = fill;
	}
	return (
		<svg
			className={classWithModifiers(`icon ${className || ''}`, [
				...(classModifiers || [])
			])}
			style={styles.svg}
			width={`${size}px`}
			height={`${size}px`}
			viewBox="0 0 24 24"
		>
			{iconPath}
		</svg>
	);
};

export default Icon;
