import { host } from './configuration';
import { fetchWithAuthentication } from './fetchWithAuthentication';

/**
 * Return the list of group of an organization
 * @param organisationId the identifier of the organization
 * @param url the URL that's need to be called. This is used when polling all pages of the API. If missing, the URL will be generated in order to get the first page.
 */
export function getGroupList(
	organisationId: string,
	url = `/v2/organizations/${organisationId}/groups?order[name]=asc`
) {
	return fetchWithAuthentication(host + url).then((data) => {
		if (!data) {
			return {};
		}
		return {
			groupList: data['hydra:member'],
			next: data['hydra:view'] ? data['hydra:view']['hydra:next'] : null,
			totalItem: data['hydra:totalItems']
		};
	});
}
