import React from 'react';

import { classWithModifiers } from '../../utils/css';

type CheckboxTypes = {
	label: string;
	value: string;
	classModifiers?: string[];
	message?: string;
	checked: boolean;
};
export const Checkbox = ({
	label,
	value,
	classModifiers,
	message,
	checked
}: Readonly<CheckboxTypes>) => {
	return (
		<label
			className={classWithModifiers('hl-checkbox', [...(classModifiers || [])])}
		>
			<input
				className="hl-checkbox__input"
				type="checkbox"
				value={value}
				defaultChecked={checked}
			/>
			<span className="hl-checkbox__label">{label && label}</span>
			{message && <span className="hl-checkbox__message">{message}</span>}
		</label>
		// ---
	);
};
