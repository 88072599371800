import { PropsWithChildren } from 'react';

import './FabButton.scss';

type FabButtonProps = {
	onClick: any;
	label: string;
};
export default function FabButton({
	onClick,
	children,
	label
}: Readonly<PropsWithChildren<FabButtonProps>>) {
	return (
		<div className="fab">
			<button
				id="switchButton"
				type="button"
				onClick={onClick}
				aria-label={label}
			>
				{children}
			</button>
		</div>
	);
}
