import React from 'react';
//navigation
import { useLocation } from 'react-router';

import { useSensorContext } from '../../components/Context/Context';
//context
//lib
import { SensorContextType } from '../../components/Context/ContextType';
import { OriginCreateContainerContext } from './Container/ContainerForm/OriginCreateContainerContext';
import { ContainerMenu } from './Container/ContainerMenu';
import { ContainerTabs } from './Container/ContainerTabs';
//style
import './WizardContainerSelector.scss';

export default function WizardContainerSelector() {
	const sensorContext: SensorContextType = useSensorContext();
	const location = useLocation();
	const fromWizard = !location.pathname.startsWith('/containers-list');
	const organizationId = fromWizard
		? sensorContext.sensor.organizationObject.id
		: sensorContext.getOrganization().replace('/v2/organizations/', '');
	const urlPrefix = fromWizard
		? '/wizard/container-selector/'
		: '/containers-list/';

	// @ts-ignore
	return (
		<OriginCreateContainerContext.Provider
			value={{ fromWizard, organizationId, urlPrefix }}
		>
			<div id="ContainerSelector">
				<ContainerMenu />
				<ContainerTabs />
			</div>
		</OriginCreateContainerContext.Provider>
	);
}
