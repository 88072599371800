import { Sensor } from '../../../components/Context/ContextType';
import { ICONS } from '../../../components/Icons/icons';
import Tag, { ContainerTag, StatusTag } from '../../../components/Tag';
import './SummaryContainerPanel.scss';
import { SummaryPhoto } from './SummaryPhoto';

type SummaryContainerPanelTypes = {
	sensor: Sensor;
};
export const SummaryContainerPanel = ({
	sensor
}: Readonly<SummaryContainerPanelTypes>) => {
	return (
		<div className="summary-container-panel summary__blocks_sections summary__contract-report">
			<dl className="column dl-container">
				<dt>Contenant</dt>
				<dd>
					<SummaryPhoto src={sensor.container?.picture} />
					<div className="container">
						<h3>{sensor.container.name}</h3>
					</div>
				</dd>
				<dd>
					<StatusTag logisticStatus={sensor.container.logisticStatus} />
					<ContainerTag tags={sensor.container.tags} />
					{!!sensor.container.depositPoint && (
						<Tag
							label={sensor.container.depositPoint.name}
							classModifiers={['default-status']}
							iconLeft={ICONS.CIRCLE}
						/>
					)}
				</dd>
			</dl>
		</div>
	);
};
