type SummaryPhotoTypes = {
	src?: string;
};

export const SummaryPhoto = ({ src }: Readonly<SummaryPhotoTypes>) => {
	if (src) {
		return <img src={`${src}?width=48&height=60`} alt="" />;
	}

	return <img src="/images/placeholder_container.svg" alt="" />;
};
