// React
//api
import pThrottle from 'p-throttle';
import { useState } from 'react';
// Components
import 'react-html5-camera-photo/build/css/index.css';
//Scss
import 'react-html5-camera-photo/build/css/index.css';
import { useHistory } from 'react-router';

import {
	endContainerActiveContractByIri,
	tagContainer,
	tagSensor,
	updateSensorAndCreateContract,
	updateSensorOrganisation
} from '../../api';
import { stopTracker } from '../../api/tracker';
//Context
import { useSensorContext } from '../../components/Context/Context';
import { SensorContextType } from '../../components/Context/ContextType';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import WizardFooter from '../../components/Footer/WizardFooter';
import SignalStrengthIcon from '../../components/SignalStrengthIcon/SignalStrengthIcon';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import { CommentsBlock } from './Comment/WizardComment';
import { SummaryContainerPanel } from './Summary/SummaryContainerPanel';
import './WizardSummary.scss';

export default function WizardSummary() {
	const history = useHistory();
	const { sensor }: SensorContextType = useSensorContext();
	const [pending, setPending] = useState(false);
	const [hasError, setHasError] = useState(false);
	const asyncError = useAsyncError();
	const [askForComment, setAskForComment] = useState<boolean>(false);

	const validate = async () => {
		setPending(true);

		if (sensor.removeContainerContracts) {
			for (let activeContract of sensor.container.activeContracts) {
				await endContainerActiveContractByIri(activeContract['@id'], {
					endedAt: new Date().toISOString()
				});
			}
		}

		/**
		 * Si nous avons enregistrez lors du wizard des capteurs a taggué, nous notifions l'API
		 */
		if (sensor.tagKoSensors) {
			for (let serialNumber of sensor.tagKoSensors) {
				await tagSensor(serialNumber, sensor.tagValueKoSensors ?? 'sensor-ko');
			}
		}

		/**
		 * Si nous avons passé l'étape de la mesure outdoor
		 */
		if (!!sensor.tagMissOutdoor) {
			await tagSensor(sensor.serialNumber, 'install-measure-skipped');
		}

		/**
		 * Si nous avons indiquer que nous ne connaissions pas les mesures du container, nous le taggons
		 */
		if (sensor.unknownContainerMeasure) {
			await tagContainer(sensor.container.id!, 'unclear-boundaries');
		}

		/**
		 * Si nous avons demander un changement d'organisation pour le sensor
		 */
		if (!!sensor.updateSensorOrganisation) {
			await updateSensorOrganisation(
				sensor.updateSensorOrganisation.organzationId,
				sensor.updateSensorOrganisation.sensorId
			);
			await tagSensor(sensor.serialNumber, 'switched-from-spare');
		}

		updateSensorAndCreateContract(sensor)
			.then(() => {
				setPending(false);
				history.push('/wizard/end');
			})
			.catch((error) => {
				if (hasError) {
					setPending(true);
					asyncError(`Veuillez contacter un administrateur technique.`);
				} else {
					setHasError(true);
					setPending(false);
					asyncError(error);
				}
			})
			.finally(() => {
				stopTracker();
			});
	};
	if (askForComment) {
		return (
			<CommentsBlock
				onCancel={() => setAskForComment(false)}
				containerId={sensor.container.id!}
			/>
		);
	}

	return (
		<div id="WizardSummary" className="page">
			<div id="content" className="summary">
				<h2>Récapitulatif</h2>
				<div className="summary__blocks">
					<div className=" summary__blocks_sections summary__contract-report">
						<dl>
							<dt>Capteur</dt>
							<dd>{sensor.serialNumber}</dd>
						</dl>
					</div>
					<div className="summary__blocks_sections summary__contract-report summary__signal-strength ">
						<dl>
							<dt>Réseau</dt>
							<dd>
								<span>
									{sensor.provider === 'LIVE_OBJECT' ? 'LoRa' : sensor.provider}
								</span>
								<SignalStrengthIcon signalStrength={sensor.signalStrength} />
							</dd>
						</dl>
					</div>
					<div className="summary__blocks_sections summary__contract-report">
						<dl>
							<dt>Mesure (à l'extérieur)</dt>
							<dd>
								{sensor.outdoorMeasure?.measureValue
									? sensor.outdoorMeasure.measureValue + 'mm'
									: 'indéfinie'}
							</dd>
							<dt>Mesure (à l'intérieur)</dt>
							<dd>
								{sensor.indoorMeasure?.measureValue
									? sensor.indoorMeasure.measureValue + 'mm'
									: 'indéfinie'}
							</dd>
						</dl>
					</div>
					<SummaryContainerPanel sensor={sensor} />

					{!askForComment && (
						<button
							type="button"
							className="summary__wizard-note_button"
							onClick={() => {
								setAskForComment(true);
							}}
						>
							Ajouter une commentaire
						</button>
					)}
				</div>
			</div>
			<WizardStepper activeIndex={9} />
			<WizardFooter
				disabledNext={pending}
				nextLabel={hasError ? 'Réessayer' : 'Valider'}
				next={pThrottle({
					limit: 1,
					interval: 1000
				})(validate)}
				loader={pending}
			/>
		</div>
	);
}
