import ReactCamera, {
	FACING_MODES,
	IMAGE_TYPES
} from 'react-html5-camera-photo';

import { FlashComponent } from '../../../components/FlashLight/FlashLight';
import useFlash from '../../../components/Hook/useFlash';
import ArrowLeft from '../../../svg/ArrowLeft';

type CameraTypes = {
	onSave: (uri: string) => void;
	onCancel: () => void;
	onError?: (error: any) => void;
};
export const Camera = ({
	onSave,
	onCancel,
	onError
}: Readonly<CameraTypes>) => {
	const [flash, toggleFlash] = useFlash();

	return (
		<>
			<button
				onClick={() => {
					toggleFlash();
					onCancel();
				}}
				className="back"
				aria-label="Revenir en arrière"
			>
				<ArrowLeft />
			</button>
			<FlashComponent flash={flash} toggle={toggleFlash} />
			<ReactCamera
				isImageMirror={false}
				imageType={IMAGE_TYPES.JPG}
				idealResolution={{
					width: window.innerWidth,
					height: window.innerWidth
				}}
				isMaxResolution={false}
				idealFacingMode={FACING_MODES.ENVIRONMENT}
				isFullscreen={false}
				onTakePhoto={onSave}
				isDisplayStartCameraError={true}
				onCameraError={onError}
			/>
		</>
	);
};
