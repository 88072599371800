import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import {
	createPav,
	fetchAllDepositPoints,
	generateDepositPointsUrl
} from '../../../../../api/depositPoints';
import { Option } from '../../../../../api/model';
import { ApiGetDepositPoint } from '../../../../../api/model/container';
import { genericSelectStyles } from '../../../../../components/select';
import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';
import {
	convertDepositPointToOption,
	OptionWithAddress
} from './convertDepositPointToOption';

type DepositSelectTypes = {
	onChange: (value: Option) => void;
	value?: Option;
};
export const DepositSelect = ({
	onChange,
	value
}: Readonly<DepositSelectTypes>) => {
	const [key, setKey] = useState('');
	const { organizationId } = useOriginCreateContainerContext();

	const loadOptions = (search: string) => {
		return fetchAllDepositPoints(
			organizationId,
			`${generateDepositPointsUrl(organizationId)}?name=${search}`
		).then((depositPoints) => {
			return depositPoints
				.map(convertDepositPointToOption)
				.filter((option) => !!option);
		});
	};

	const onCreateOptionHandler = async (name: string) => {
		const depositPoint: ApiGetDepositPoint = await createPav(
			name,
			organizationId
		).then((response) => response.json());

		setKey(depositPoint['@id']);

		onChange(convertDepositPointToOption(depositPoint)!);
	};
	return (
		<AsyncCreatableSelect
			key={key}
			styles={genericSelectStyles}
			defaultOptions
			cacheOptions
			placeholder="PAV"
			loadOptions={loadOptions}
			onCreateOption={onCreateOptionHandler}
			value={value}
			onChange={(value: unknown) => onChange(value as Option)}
			getOptionLabel={(option: OptionWithAddress) => (
				<>
					<p>{option.label}</p>
					<p>{option.address}</p>
				</>
			)}
		></AsyncCreatableSelect>
	);
};
