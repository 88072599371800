import React from 'react';
import { createRoot } from 'react-dom/client';
import { Talkr } from 'talkr';

import App from './App';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import './index.scss';
import { initMonitoring } from './monitoring';
import reportWebVitals from './reportWebVitals';

initMonitoring();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<Talkr languages={{ en, fr }} defaultLanguage="fr">
		<App />
	</Talkr>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
