import { PropsWithChildren } from 'react';

import BackButton from '../BackButton/BackButton';
import NextButton from '../NextButton/NextButton';
import './WizardFooter.scss';

type WizardFooterProps = {
	disabledNext?: boolean;
	next: any;
	nextLabel?: string;
	previousLabel?: string;
	previous?: any;
	loader?: boolean;
	withBackButton?: boolean;
};
export default function WizardFooter({
	disabledNext,
	next,
	nextLabel,
	previousLabel,
	previous,
	loader,
	children,
	withBackButton = true
}: Readonly<PropsWithChildren<WizardFooterProps>>) {
	return (
		<footer>
			{withBackButton && (
				<BackButton previous={previous} label={previousLabel} />
			)}
			{children}
			<NextButton
				disabled={disabledNext}
				next={next}
				label={nextLabel}
				loader={loader}
			/>
		</footer>
	);
}
