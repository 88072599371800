const LOCALSTORAGE_KEY = 'coords';
const TIMEOUT = 5000;
const RETRY = 2;

export type GeolocationPositionOrigin =
	| 'geolocation'
	| 'localstorage'
	| 'unknown';
export type GeolocationPositionWithOrigin = GeolocationPosition & {
	origin: GeolocationPositionOrigin;
};

const convertGeolocationPositionToString = (position: GeolocationPosition) => {
	return JSON.stringify({
		latitude: position.coords.latitude,
		longitude: position.coords.longitude,
		accuracy: position.coords.accuracy,
		altitude: position.coords.altitude,
		altitudeAccuracy: position.coords.altitudeAccuracy,
		heading: position.coords.heading,
		speed: position.coords.speed
	});
};

const saveGeolocationPositionToLocalStorage = (
	position: GeolocationPosition
) => {
	localStorage.setItem(
		LOCALSTORAGE_KEY,
		convertGeolocationPositionToString(position)
	);
};

const getGeolocationPositionFromLocalStorage =
	(): GeolocationPositionWithOrigin | null => {
		const coords = localStorage.getItem(LOCALSTORAGE_KEY);
		if (!coords) {
			return {
				coords: {} as GeolocationCoordinates,
				timestamp: 0,
				origin: 'unknown'
			};
		}
		return { coords: JSON.parse(coords), timestamp: 0, origin: 'localstorage' };
	};

const getCurrentPositionPromise =
	(): Promise<GeolocationPositionWithOrigin> => {
		console.log('[getCurrentPositionPromise] Getting current position...');
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) =>
					resolve({
						coords: position.coords,
						timestamp: position.timestamp,
						origin: 'geolocation'
					}),
				reject,
				{ timeout: TIMEOUT }
			);
		});
	};

export async function getCurrentPosition(): Promise<GeolocationPositionWithOrigin> {
	let position: GeolocationPositionWithOrigin | null = null;

	let retries = 0;
	do {
		try {
			position = await getCurrentPositionPromise();
		} catch (e) {
			retries++;
		}
	} while (retries < RETRY && !position);

	if (!position) {
		position = getGeolocationPositionFromLocalStorage();
	} else {
		saveGeolocationPositionToLocalStorage(position);
	}

	console.log('[getCurrentPositionPromise] Getting current position', position);
	return position!;
}
