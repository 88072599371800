import Header, {
	HeaderTitleWithBackButton
} from '../../../components/Header/Header';
import { useOriginCreateContainerContext } from './ContainerForm/OriginCreateContainerContext';

export const ContainerMenu = () => {
	const { fromWizard } = useOriginCreateContainerContext();

	if (fromWizard) {
		return (
			<HeaderTitleWithBackButton>
				<>Sélectionner un contenant</>
			</HeaderTitleWithBackButton>
		);
	}

	return <Header></Header>;
};
