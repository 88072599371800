import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router';

import { Cover } from '../../../components/Animation/Animation';
import WizardFooter from '../../../components/Footer/WizardFooter';
import WizardStepper from '../../../components/WizardStepper/WizardStepper';
import { PhotoBottomSheet } from './PhotoBottomSheet';

type PhotoHomeTypes = {
	loading: boolean;
	onNext: () => void;
	onPhotoUpload: (dataUri: string) => Promise<void>;
	onExistingPhotoSelect: (url: string) => void;
};
export const PhotoHome = ({
	loading,
	onNext,
	onPhotoUpload,
	onExistingPhotoSelect
}: Readonly<PhotoHomeTypes>) => {
	const history = useHistory();
	const [bottomSheet, setBottomSheet] = useState(false);

	const onPass = () => {
		setBottomSheet(false);
		history.push('/wizard/geolocation');
	};
	const onFileUploaded = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				if (reader.result) {
					onPhotoUpload(reader.result.toString());
				}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};
	return (
		<div id="PhotoOff" className="page wizard-page">
			<Cover path={'/images/big/take_picture'} />

			<button id="Pass" onClick={onPass}>
				Passer
			</button>
			<div className="wizard-page__content">
				<h2>Prendre une photo</h2>
				<p>
					La photo du contenant permet de mieux le reconnaître pour les futures
					installations ou remplacements.
				</p>
				<p>Votre photo doit respecter les préconisations suivantes :</p>
				<ul>
					<li>
						<img src="/images/check.svg" alt="" />
						L'ensemble du contenant est visible
					</li>
					<li>
						<img src="/images/check.svg" alt="" />
						L'exposition de votre photo est adaptée
					</li>
				</ul>
			</div>
			<WizardStepper activeIndex={7} />
			<WizardFooter
				next={() => setBottomSheet(true)}
				previous={() => history.goBack()}
				loader={loading}
			/>

			<PhotoBottomSheet
				open={bottomSheet}
				onPass={onPass}
				onFileUpload={onFileUploaded}
				onNewPhoto={onNext}
				onLastPhotoSelect={onExistingPhotoSelect}
			/>
		</div>
	);
};
