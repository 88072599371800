import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useT } from 'talkr';

import { addComments, getAllComments } from '../../../api/comment';
import {
	ApiGetComment,
	ApiPutPostCommentBody
} from '../../../api/model/comment';
import { useSensorContext } from '../../../components/Context/Context';
import { HeaderTitleWithBackButton } from '../../../components/Header/Header';
import { resizeReactCameraPicture } from '../../../lib/Helper/PhotoHelper';
import { Camera } from '../Photo/Camera';
import { generateImageName, saveToMinioBucket } from '../Photo/MinioClient';
import { PhotoBottomSheet } from '../Photo/PhotoBottomSheet';
import { PhotoWrapper } from '../Photo/PhotoWrapper';
import { CommentCard } from './CommentCard';
import './WizardComment.scss';

type CommentsBlockTypes = {
	containerId: string;
	onCancel?: () => void;
};
export const CommentsBlock = ({
	containerId,
	onCancel
}: Readonly<CommentsBlockTypes>) => {
	const form = useRef<HTMLFormElement>(null);
	const { T } = useT();
	const inputRef = useRef<HTMLInputElement>(null);
	const [bottomSheet, setBottomSheet] = useState(false);
	const [loading, setLoading] = useState(false);
	const [camera, setCamera] = useState(false);

	const [comments, setComments] = useState<ApiGetComment[]>([]);
	const sensorContext = useSensorContext();

	useEffect(() => {
		getAllComments(containerId).then((c) => setComments(c.reverse()));
	}, [containerId]);

	const generateComment = (picture: string | null = null) => {
		const data = new FormData(form.current!);

		const value = Object.fromEntries(data.entries()) as { content: string };

		const newComment: ApiPutPostCommentBody = {
			...value,
			container: `/v2/containers/${containerId}`,
			picture,
			sensor: null
		};

		return newComment;
	};

	const addNewCommentToList = (comment: ApiGetComment) => {
		setComments([...comments, comment]);
		scrollToBottom();
		setBottomSheet(false);
		form.current!.reset();
	};
	const onSubmit = (
		picture: string | null = null,
		comment?: ApiGetComment,
		needUpload?: boolean
	) => {
		const newComment = generateComment(picture);
		if (needUpload) {
			newComment.content = comment?.content ?? '';
		}
		return addComments(containerId, newComment).then((comment) => {
			if (needUpload) {
				const original = [...comments];
				original.pop();
				setComments([...original]);
			}
			addNewCommentToList(comment);
		});
	};

	const resizeImage = useCallback(resizeReactCameraPicture, []);

	const onDisplayPartialImage = (photoUri: string) => {
		const comment = generateComment(photoUri) as unknown as ApiGetComment;
		addNewCommentToList(comment);
		return comment;
	};
	const savePhotoInAzure = async (
		photoUri: string,
		comment?: ApiGetComment
	) => {
		setLoading(true);
		if (!loading) {
			const name = generateImageName(sensorContext.getUserOrganization().id);
			await saveToMinioBucket(photoUri, name, () => {
				const url = `${process.env.REACT_APP_HEY_MEDIA_API}/${name}`;
				onSubmit(url, comment, true);
				setLoading(false);
			});
		}
	};

	const scrollToBottom = () => {
		inputRef.current?.scrollIntoView({ behavior: 'auto', inline: 'end' });
		inputRef.current?.focus();
	};

	useEffect(scrollToBottom, [comments.length]);

	return (
		<div className="page comments-page">
			<PhotoWrapper hidden={!camera} className="comment-photo">
				<Camera
					onSave={async (dataUri) => {
						const resize = await resizeImage(dataUri);

						const comment = onDisplayPartialImage(resize);
						savePhotoInAzure(resize, comment);
						setCamera(false);
					}}
					onCancel={() => {
						setCamera(false);
					}}
				/>
			</PhotoWrapper>

			<div hidden={camera}>
				<HeaderTitleWithBackButton onCancel={onCancel}>
					<>{T('comment.title')}</>
				</HeaderTitleWithBackButton>

				<div className="comments-wrapper">
					<ul>
						{comments.map((c) => {
							return (
								<li key={c['@id']}>
									<CommentCard comment={c} />
								</li>
							);
						})}
					</ul>

					<form ref={form}>
						<input
							ref={inputRef}
							className="hl-input-text__input"
							name="content"
							aria-label={T('comment.input_placeholder') as string}
							placeholder={T('comment.input_placeholder') as string}
						/>
						<button
							type="button"
							className="btn btn--small"
							onClick={() => setBottomSheet(true)}
						>
							{T('comment.send')}
						</button>
						<PhotoBottomSheet
							open={bottomSheet}
							onFileUpload={(e: ChangeEvent<HTMLInputElement>) => {
								if (e.target.files) {
									const reader = new FileReader();
									reader.addEventListener('load', async () => {
										if (reader.result) {
											const dataUri = reader.result.toString();
											const resize = await resizeImage(dataUri);
											const comment = onDisplayPartialImage(resize);
											savePhotoInAzure(resize, comment);
										}
									});
									reader.readAsDataURL(e.target.files[0]);
								}
							}}
							onNewPhoto={() => setCamera(true)}
							onLastPhotoSelect={(url) => onSubmit(url)}
							onValidate={() => onSubmit()}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export const WizardComment = () => {
	const { containerId } = useParams<{ containerId: string }>();

	return <CommentsBlock containerId={containerId} />;
};
