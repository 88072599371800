import React, { useState } from 'react';

import {
	removeTagContainer,
	tagContainer,
	updateContainer
} from '../../../../api';
import { ApiGetContainer } from '../../../../api/model/container';
import { useContainerFormDataContext } from '../ContainerFormDataContext';
import { ContainerForm, FormContainer } from './ContainerForm';
import { convertDepositPointToOption } from './deposit/convertDepositPointToOption';
import { convertFlowToOption } from './flows/convertFlowToOption';
import { convertGroupToOption } from './groups/convertGroupToOption';
import { convertModelToOption } from './models/convertModelToOption';
import { convertStatusToOption } from './status/convertStatusToOption';

type UpdateFormTypes = {
	container: ApiGetContainer;
	onCancel: () => void;
	onSave: (container: ApiGetContainer) => void;
};

export const UpdateForm = ({
	container: apiContainer,
	onCancel,
	onSave
}: Readonly<UpdateFormTypes>) => {
	const { models, groups, flows, depositPoints } =
		useContainerFormDataContext();

	const [container, setContainer] = useState<FormContainer>({
		name: apiContainer.name,
		flow: convertFlowToOption(
			flows.find(
				(f) => f['@id'] === apiContainer.flow || f['key'] === apiContainer.flow
			)
		),
		model: convertModelToOption(
			models.find((m) => m['@id'] === apiContainer?.model?.['@id'])
		),
		group: convertGroupToOption(
			groups.find((g) => g['@id'] === apiContainer?.group?.['@id'])
		),
		statut: convertStatusToOption(apiContainer.logisticStatus),
		tags: apiContainer.tags,
		deposit: convertDepositPointToOption(
			depositPoints.find((d) => d['@id'] === apiContainer.depositPoint?.['@id'])
		),
		latitude: apiContainer.latitude,
		longitude: apiContainer.longitude
	});

	const save = async (container: FormContainer) => {
		await Promise.all([
			...(container.tags ?? []).map((tag) =>
				tagContainer(apiContainer.id, tag.replace('/v2/tags/', ''))
			),
			...(apiContainer?.tags ?? [])
				.filter((tag: string) => !container.tags?.includes(tag))
				.map((tag: string) =>
					removeTagContainer(apiContainer.id, tag.replace('/v2/tags/', ''))
				)
		]);

		const updatedContainer = await updateContainer(apiContainer.id, {
			name: container.name,
			flow: container.flow?.value,
			model: container.model?.value,
			group: container.group?.value,
			logisticStatus: container.statut?.value,
			depositPoint: container.deposit?.value,
			properties: models?.find(
				(model) => model['@id'] === container?.model?.value
			)?.properties,
			latitude: container.latitude,
			longitude: container.longitude
		}).then((response) => response.json());

		onSave(updatedContainer);
	};

	return (
		<div id="container-form">
			<ContainerForm
				container={container}
				setContainer={setContainer}
				models={models}
				groups={groups}
				flows={flows}
				onUpdate={save}
				tags={true}
				onCancel={onCancel}
			/>
		</div>
	);
};
