import { useHistory } from 'react-router';

import { Button } from '../../lib/Buttons/Button';
import ArrowLeft from '../../svg/ArrowLeft';

type BackButtonProps = {
	previous?: any;
	label?: string;
};
export default function BackButton({
	previous,
	label = 'Précédent'
}: Readonly<BackButtonProps>) {
	const history = useHistory();

	const onPrevious = () => {
		previous ? previous() : history.goBack();
	};
	return (
		<Button
			label={label}
			type="button"
			onClick={onPrevious}
			classModifiers={['white']}
			iconLeft={<ArrowLeft />}
		/>
	);
}
