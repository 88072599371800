import * as Sentry from '@sentry/react';
import { ChangeEvent, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router';

import { getUserHasAccessToMultipleOrgs } from '../api';
import { authenticate } from '../api/authentication';
import { clearLocalStorage } from '../api/clearLocalStorage';
import { useSensorContext } from '../components/Context/Context';
import { SensorContextType } from '../components/Context/ContextType';
import { useAsyncError } from '../components/ErrorBoundaries/ErrorBoundaries';
import { Button } from '../lib/Buttons/Button';
import { InputText } from '../lib/Forms/InputText/InputText';
import { identify } from '../monitoring/logrocket';
import { Loader } from '../svg/loader';
import './Login.scss';

export default function Login() {
	const sensorContext: SensorContextType = useSensorContext();

	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordInputType, setPasswordInputType] = useState('password');
	const [loading, setLoading] = useState(false);
	const throwError = useAsyncError();
	const disabled = loading || password === '' || email === '';

	const login = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();

		clearLocalStorage();
		setLoading(true);
		authenticate(email, password)
			.then(() => {
				Sentry.setUser({ email: email });
				identify(email);
			})
			.then(() => {
				return getUserHasAccessToMultipleOrgs().then((bool) => {
					sensorContext.setAccessToMulipleOrgs(bool);
					return bool;
				});
			})
			.then((hasMultipleOrganization) => {
				if (hasMultipleOrganization) {
					history.replace('/organization?login=true');
				} else {
					history.replace('/search');
				}
			})
			.catch(() => {
				throwError('login_error');
				setLoading(false);
			});
	};

	const showPassword = () => {
		setPasswordInputType(
			passwordInputType === 'password' ? 'text' : 'password'
		);
	};

	return (
		<div id="Login" className="page">
			<div className="login-background">
				<img
					className="login-background__logo"
					src="/images/logo_512px.png"
					alt=""
				/>
				<img
					className="login-background__worker-image"
					src="/images/login.svg"
					alt=""
				/>
			</div>
			<div className="login-form">
				<h2>Connexion</h2>
				<form>
					<InputText
						type="text"
						label="EMAIL"
						value={email}
						placeholder="Nom.Prénom@heyliot.app"
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setEmail(e.target?.value)
						}
					/>
					<div id="password">
						<InputText
							type={passwordInputType}
							label="MOT DE PASSE"
							value={password}
							placeholder="Saisir votre mot de passe"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setPassword(e.target.value)
							}
						/>
						<button type="button" onClick={showPassword}>
							{passwordInputType === 'password' && (
								<img
									src="/images/visibility-off.svg"
									alt="afficher le mot de passe"
								/>
							)}
							{passwordInputType === 'text' && (
								<img
									src="/images/visibility-on.svg"
									alt="cacher le mot de passe"
								/>
							)}
						</button>
					</div>

					<Button
						disabled={disabled}
						type="submit"
						onClick={login}
						label={
							<>
								{loading && <Loader />}
								Se connecter
							</>
						}
					/>
				</form>
			</div>
			<div id="version">{process.env.REACT_APP_APP_VERSION}</div>
		</div>
	);
}
