import { ApiGetFlow } from '@api/model/container';

import Tag from './';

type ContainerFlowTagProps = {
	flow: string;
	flows: ApiGetFlow[];
};

export const ContainerFlowTag = ({ flow, flows }: ContainerFlowTagProps) => {
	if (!flows) {
		return null;
	}
	const currentFlow = flows.find((f) => f.key === flow);
	const currentColor = 'white';
	const backgroundColor = `${currentFlow?.color ?? 'black'}`;
	if (!!currentFlow) {
		return (
			<Tag
				styles={{ color: currentColor, backgroundColor }}
				label={currentFlow!.label ?? ''}
			/>
		);
	}
	return null;
};
