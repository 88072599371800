import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { initLogRocket } from './logrocket';

export const initMonitoring = async () => {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_FEATURE_ENV,
		integrations: [new Integrations.BrowserTracing()],
		release: 'hey-mobile-installer@' + process.env.REACT_APP_APP_VERSION,
		tracesSampleRate: 1.0
	});

	const url = await initLogRocket();
	if (url) {
		Sentry.configureScope((scope) => scope.setExtra('sessionURL', url));
	}
};
