import { Client } from 'minio';

import { dataURItoBlob, toBuffer } from '../../../lib/Helper/PhotoHelper';

const minioClient = new Client({
	endPoint: process.env.REACT_APP_HEY_MINIO_ENDPOINT,
	accessKey: process.env.REACT_APP_HEY_MINIO_ACCESS_KEY,
	secretKey: process.env.REACT_APP_HEY_MINIO_SECRET_KEY,
	port: 443,
	useSSL: true
});

export const saveToMinioBucket = async (
	photoUri: string,
	name: string,
	callback: () => void
) => {
	const arrayBuffer = await dataURItoBlob(photoUri!).arrayBuffer();
	return minioClient.putObject(
		process.env.REACT_APP_HEY_MINIO_BUCKET,
		name,
		toBuffer(arrayBuffer),
		callback
	);
};

export const generateImageName = (folder: string) => {
	const random = Math.floor(Math.random() * (10 - 0 + 1));
	return `${folder}/${new Date().getTime()}-${random}.jpg`;
};
