import { Sensor } from '../../../components/Context/ContextType';
import { Button } from '../../../lib/Buttons/Button';
import { SensorCardLastMeasure } from '../sensorCardLastMeasure';

type SensorCardProps = {
	onSensorClick: (sensor: Sensor) => void;
	onSearch: (sensor: string) => void | Promise<any> | null;
	sensors: Sensor[];
};

export const SensorCard = ({
	sensors,
	onSensorClick,
	onSearch
}: SensorCardProps) => {
	return (
		<ul id="searchResult" className="SearchResult">
			{sensors.map((sensor) => (
				<li key={sensor.serialNumber}>
					<h3>
						<span>{sensor.serialNumber}</span>
						<span>{sensor.organizationObject?.name}</span>
					</h3>

					<SensorCardLastMeasure sensor={sensor} />

					<div className="search__action-bar">
						<Button
							type="button"
							label="Sélectionner"
							classModifiers={['small']}
							onClick={() => onSensorClick(sensor)}
						/>
						<Button
							type="button"
							label={<span>&#10227;</span>}
							ariaLabel="Rafraichir"
							classModifiers={['small']}
							onClick={() => onSearch(sensor.serialNumber)}
						/>
					</div>
				</li>
			))}
		</ul>
	);
};
