import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { getMeasure } from '../../api';
import { FOUND, NOTFOUND, PENDING } from '../../api/model';
import { clearIntervals } from '../../api/utils';
import { Cover } from '../../components/Animation/Animation';
import { useSensorContext } from '../../components/Context/Context';
import { SensorContextType } from '../../components/Context/ContextType';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import Loading from '../../components/FabButton/Loading/Loading';
import WizardFooter from '../../components/Footer/WizardFooter';
import Modal from '../../components/Modal/Modal';
import RetryButton from '../../components/RetryButton/RetryButton';
import { SensorSerialNumberWithIcon } from '../../components/SensorSerialNumberWithIcon/SensorSerialNumberWithIcon';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import { DateFromNow } from '../../lib/Helper/DateHelper/DateHelper';
import { Information } from './Measure/Information';
import './WizardCommonStyle.scss';
import './WizardMeasure.scss';

export default function WizardMeasure() {
	const location = useLocation();
	const isMeasureIndoor = location.search.includes('measure=indoor');
	const history = useHistory();
	const sensorContext: SensorContextType = useSensorContext();
	const [status, setStatus] = useState(NOTFOUND);
	const [shouldDisplayConfirmationModal, setShouldDisplayConfirmationModal] =
		useState(false);
	const throwError = useAsyncError();

	useEffect(() => {
		return () => {
			clearIntervals();
		};
	}, []);

	const getMeasureData = () => {
		setStatus(PENDING);
		getMeasure(sensorContext.sensor.serialNumber)
			.then((sensorMeasure) => {
				setStatus(FOUND);
				sensorContext.setSensor({
					...sensorContext.sensor,
					tagMissOutdoor: isMeasureIndoor
						? (sensorMeasure.tagMissOutdoor ?? false)
						: false,
					[isMeasureIndoor ? 'indoorMeasure' : 'outdoorMeasure']: sensorMeasure
				});
			})
			.catch((error) => {
				setStatus(NOTFOUND);
				throwError(error);
			});
	};

	const goNext = () => {
		if (status === NOTFOUND) {
			getMeasureData();
		} else {
			if (isMeasureIndoor) {
				goToPhoto();
			} else {
				history.push('/wizard/container-selector');
			}
		}
	};

	const goToPhoto = () => history.push('/wizard/photo');
	const measure = isMeasureIndoor
		? sensorContext.sensor.indoorMeasure
		: sensorContext.sensor.outdoorMeasure;

	return (
		<>
			{shouldDisplayConfirmationModal && (
				<Modal>
					<h3>Attention</h3>
					<p>Etes-vous sûr de vouloir ignorer cette première mesure ?</p>
					<p className="flex-justify-around">
						<button
							type="button"
							className="btn btn--small btn--secondary"
							onClick={() => {
								setShouldDisplayConfirmationModal(false);
							}}
						>
							<span className="btn__label">Non</span>
						</button>
						<button type="button" className="btn btn--small">
							<span
								className="btn__label btn__label--white"
								onClick={() => {
									setShouldDisplayConfirmationModal(false);
									sensorContext.setSensor({
										...sensorContext.sensor,
										tagMissOutdoor: true
									});
									history.push('/wizard/container-selector');
								}}
							>
								Oui
							</span>
						</button>
					</p>
				</Modal>
			)}
			<div
				id="WizardMeasure"
				className={
					'page wizard-page measure-' + (isMeasureIndoor ? 'indoor' : 'outdoor')
				}
			>
				<Cover
					path={
						'/images/big/' +
						(isMeasureIndoor ? 'measure_inside' : 'measure_outside')
					}
				/>
				{!isMeasureIndoor && (
					<button
						id="Pass"
						onClick={() => setShouldDisplayConfirmationModal(true)}
					>
						Passer
					</button>
				)}

				{isMeasureIndoor && (
					<button id="Pass" onClick={goToPhoto}>
						Passer
					</button>
				)}
				<div className="wizard-page__content">
					<h2 className="wizard-page__content_title">Prise d'une mesure</h2>
					<SensorSerialNumberWithIcon
						serialNumber={sensorContext.sensor.serialNumber}
					/>

					{status === NOTFOUND && <Information sensor={sensorContext.sensor} />}

					{status === PENDING && <Loading text={"En attente d'une mesure"} />}
					{status === FOUND && (
						<div id="result">
							<div id="measure">
								<p>
									<span>{measure?.measureName}</span>
									<span>{`${measure?.measureValue / 1000}`} m</span>
								</p>
								<DateFromNow
									date={measure?.measureDate}
									locale="fr"
									prefix="Prise"
								/>
							</div>
							<RetryButton onClick={() => getMeasureData()} />
						</div>
					)}
				</div>

				<WizardStepper activeIndex={isMeasureIndoor ? 6 : 2} />
				<WizardFooter
					disabledNext={status === PENDING}
					next={() => goNext()}
					previous={() => history.goBack()}
				/>
			</div>
		</>
	);
}
