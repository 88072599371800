import './SensorSerialNumberWithIcon.scss';

type SensorSerialNumberWithIconProps = {
	serialNumber: string;
};

export function SensorSerialNumberWithIcon({
	serialNumber
}: Readonly<SensorSerialNumberWithIconProps>) {
	return (
		<p id="sensorName">
			<img src="/images/sensor_h.svg" alt="" width="50px" height="50px" />
			<span>{serialNumber}</span>
		</p>
	);
}
