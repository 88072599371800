import { Button, ButtonProps } from '../../lib/Buttons/Button';
import './RetryButton.scss';

export default function RetryButton({
	onClick
}: Readonly<Pick<ButtonProps, 'onClick'>>) {
	return (
		<Button
			label="Réessayer"
			type="button"
			classModifiers={['small', 'retry']}
			onClick={onClick}
		/>
	);
}
