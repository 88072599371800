import { ChangeEvent } from 'react';

//context
import { Option } from '../../../../api/model';
//styles
import NextButton from '../../../../components/NextButton/NextButton';
import { Select } from '../../../../components/select';
import { Button } from '../../../../lib/Buttons/Button';
import { InputText, Label } from '../../../../lib/Forms/InputText/InputText';
import { FormContainer } from './ContainerForm';
import './ContainerForm.scss';
import { DepositSelect } from './deposit/DepositSelect';
import { convertFlowToOption } from './flows/convertFlowToOption';
import { convertGroupToOption } from './groups/convertGroupToOption';
import { convertModelToOption } from './models/convertModelToOption';
import { StatusSelect } from './status/StatusSelect';
import { TagsCheckboxGroup } from './tags/TagsCheckboxGroup';

export const ContainerFormStep1 = ({
	hasDP,
	container,
	onSave,
	models,
	groups,
	flows,
	onNext,
	onCancel,
	tags
}: Readonly<{
	hasDP: boolean;
	container: FormContainer;
	models: any[];
	groups: any[];
	flows: any[];
	tags?: boolean;
	onSave: (container: FormContainer) => void;
	onNext: () => void;
	onCancel?: () => void;
}>) => {
	const groupsOptions = groups.map(convertGroupToOption);
	const modelsOptions = models?.map(convertModelToOption);
	const flowsOptions = flows.map(convertFlowToOption);

	const isFormInvalid =
		!container.name || !container.group || !container.model || !container.flow;
	return (
		<form>
			{hasDP && (
				<Label label="PAV">
					<DepositSelect
						value={container.deposit}
						onChange={(deposit: Option) => {
							onSave({ ...container, deposit });
						}}
					></DepositSelect>
				</Label>
			)}

			<InputText
				label="Nom du container"
				value={container.name}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					onSave({ ...container, name: e.target.value })
				}
			/>

			<Label label="Groupes">
				<Select
					placeholder="Groupes"
					options={groupsOptions}
					value={container.group}
					onChange={(value: unknown) =>
						onSave({ ...container, group: value as Option })
					}
				></Select>
			</Label>

			<Label label="Model">
				<Select
					options={modelsOptions}
					value={container.model}
					onChange={(value: unknown) =>
						onSave({ ...container, model: value as Option })
					}
				></Select>
			</Label>

			<Label label="Flux">
				<Select
					options={flowsOptions}
					value={container.flow}
					onChange={(value: unknown) =>
						onSave({ ...container, flow: value as Option })
					}
				></Select>
			</Label>

			<Label label="Statut">
				<StatusSelect
					value={container.statut}
					onChange={(value: unknown) =>
						onSave({ ...container, statut: value as Option })
					}
				/>
			</Label>

			{tags && (
				<TagsCheckboxGroup
					value={container.tags}
					onChange={(tags) => onSave({ ...container, tags })}
				/>
			)}

			<p>
				{!!onCancel && (
					<Button
						type="button"
						label="Annuler"
						classModifiers={['half-width', 'white']}
						onClick={onCancel}
					></Button>
				)}

				<NextButton
					label="Sauvegarder"
					disabled={isFormInvalid}
					next={onNext}
					classModifiers={[!onCancel ? 'full-width' : 'half-width']}
				/>
			</p>
		</form>
	);
};
