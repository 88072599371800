import { useContext } from 'react';

import { Container } from '../../../components/Context/ContextType';
import { Button } from '../../../lib/Buttons/Button';
import { OriginCreateContainerContext } from '../Container/ContainerForm/OriginCreateContainerContext';

type UpdateBottomToolbarTypes = {
	onCancel: () => void;
	onSave: () => void;
	container: Container;
};
export const UpdateBottomToolbar = ({
	onSave,
	onCancel,
	container
}: Readonly<UpdateBottomToolbarTypes>) => {
	const sensorContext = useContext(OriginCreateContainerContext);

	return (
		<div className="update-container-geoloc">
			{!sensorContext?.fromWizard && (
				<h3 className="update-container-geoloc__title">{container.name}</h3>
			)}
			<div className="update-container-geoloc__buttons">
				<Button
					label="Annuler"
					type="button"
					onClick={onCancel}
					classModifiers={['white']}
				/>
				<button
					className="btn btn--small update-container-geoloc__buttons--update"
					onClick={onSave}
				>
					Modifier
				</button>
			</div>
		</div>
	);
};
