import { fetchWithAuthentication } from './fetchWithAuthentication';
import {
	ClientSideApiPayload,
	Network,
	NetWorkKey,
	Provider,
	ProviderKey,
	ServerSideApiPayload
} from './model';

export const getNetworkLabel = (key: NetWorkKey) => Network[key] ?? key;
export const getProviderLabel = (key: ProviderKey) => Provider[key] ?? key;

export const paramsObjToUrl = (params: { [key: string]: string }) => {
	let string = '';
	for (const key of Object.keys(params)) {
		const prefix = string.length ? '&' : '?';
		string += `${prefix}${key}=${params[key]}`;
	}
	return string;
};

export const commonHeaders = () => ({
	Authorization: `Bearer ${localStorage.getItem('access_token')}`,
	'content-type': 'application/json'
});

export const returnResponseOrThrowError = async (response: Response) => {
	if (response.status >= 400) {
		const body = await response.json();
		let errorMessage =
			body.message || body.errorMessage || 'error.unable_to_fetch_data';
		if (body['hydra:title'] && body['hydra:description']) {
			errorMessage = `${body['hydra:title']} - ${body['hydra:description']}`;
		}
		return Promise.reject(`${response.status}: ${errorMessage}`);
	}
	return response;
};

type OnResolveOrRejectType = (response: any) => void;

export const intervals = [];
export const clearIntervals = () => {
	intervals.forEach((interval) => clearInterval(interval));
};

export const pollData = (
	fetchFunction: (
		onResolve: OnResolveOrRejectType,
		onReject: OnResolveOrRejectType
	) => unknown,
	rejectMessage = 'error.unable_to_fetch_data',
	pollingInterval: number | null = null,
	pollingTimeout: number | null = null
) => {
	pollingInterval =
		pollingInterval || parseInt(process.env.REACT_APP_POLLING_INTERVAL) || 5000;
	pollingTimeout =
		pollingTimeout || parseInt(process.env.REACT_APP_POLLING_TIMEOUT) || 60000;
	const limit = pollingTimeout / pollingInterval;
	let i = 0;
	return new Promise((resolve, reject) => {
		let interval: NodeJS.Timeout | undefined;

		function poll() {
			i++;
			const onResolve: OnResolveOrRejectType = (...res) => {
				clearInterval(interval);
				resolve(...res);
			};
			const onReject: OnResolveOrRejectType = (...res) => {
				clearInterval(interval);
				reject(...res);
			};
			if (i === limit) {
				onReject(rejectMessage);
			} else {
				fetchFunction(onResolve, onReject);
			}
		}

		interval = setInterval(poll, pollingInterval!);
		intervals.push(interval);
		poll();
	});
};

export const fetchOnePage = <T>(
	url: string
): Promise<ClientSideApiPayload<T>> => {
	return fetchWithAuthentication(url).then((data: ServerSideApiPayload<T>) => {
		if (!data) {
			return {};
		}
		return {
			data: data['hydra:member'],
			next: data['hydra:view'] ? data['hydra:view']['hydra:next'] : null,
			totalItem: data['hydra:totalItems']
		};
	});
};

export const fetchAll = <T>(
	caller: (id: string, url?: string) => Promise<ClientSideApiPayload<T>>
) => {
	const getAll = (
		id: string,
		url?: string,
		previousData: T[] = []
	): Promise<T[]> => {
		return caller(id, url).then(({ data = [], next }) => {
			if (next) {
				return getAll(id, next, [...previousData, ...data]);
			}
			return [...previousData, ...data];
		});
	};
	return getAll;
};
