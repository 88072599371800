import React, { useEffect, useState } from 'react';

import { FlashOff, FlashOn } from '../../svg/Flash';
import useFlash, { isFlashSupported } from '../Hook/useFlash';
import './FlashLight.scss';

export const FlashComponent = ({
	flash,
	toggle
}: {
	flash: boolean;
	toggle: any;
}) => {
	const [isApiSupported, setApiSupported] = useState(false);

	useEffect(() => {
		isFlashSupported().then((result) => setApiSupported(result));
	}, []);

	if (!isApiSupported) {
		return null;
	}

	return (
		<>
			{flash && (
				<button
					onClick={() => toggle()}
					className="flash-on"
					aria-label="Désactiver le flash"
				>
					<FlashOn />
				</button>
			)}
			{!flash && (
				<button
					onClick={() => toggle()}
					className="flash-off"
					aria-label="Activer le flash"
				>
					<FlashOff />
				</button>
			)}
		</>
	);
};

export default function FlashLight() {
	const [flash, toggle] = useFlash();
	return <FlashComponent flash={flash} toggle={toggle} />;
}
