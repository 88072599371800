import {
	ApiGetContainer,
	ApiGetDepositPoint
} from '../../../../api/model/container';

export type PavWithContainers = ApiGetDepositPoint & {
	containers: ApiGetContainer[];
};
export type PavOrContainer = PavWithContainers | ApiGetContainer;
type ContainersGroupByPav = Array<PavOrContainer>;
type ContainersGroupByPavHashMap = Record<
	string,
	{
		type: 'container' | 'pav';
		items: ApiGetContainer[];
	}
>;

export const groupContainerByPav = (
	containers?: ApiGetContainer[]
): ContainersGroupByPav => {
	if (!containers) {
		return [];
	}
	const map: ContainersGroupByPavHashMap = containers.reduce(
		(acc: ContainersGroupByPavHashMap, container) => {
			if (!container.depositPoint) {
				return {
					...acc,
					[container['@id']]: {
						type: 'container',
						items: [container]
					}
				};
			}

			const depositPointId = container.depositPoint['@id'];
			return {
				...acc,
				[depositPointId]: {
					type: 'pav',
					items: [...(acc[depositPointId]?.items ?? []), container]
				}
			};
		},
		{}
	);

	return Object.values(map).map(({ type, items }) => {
		if (type === 'container') {
			return items[0] as ApiGetContainer;
		}
		return {
			...(items[0].depositPoint as ApiGetDepositPoint),
			containers: items
		};
	});
};
