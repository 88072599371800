export const statusOptions = [
	{ value: 'EQUIPPED', label: 'Equipé' },
	{ value: 'TO_EQUIP', label: 'A équiper' },
	{ value: 'NOT_EQUIPPABLE', label: 'Non équipable' },
	{ value: 'NOT_TO_EQUIP', label: 'A ne pas équiper' },
	{ value: 'NOT_ACCESSIBLE', label: 'Pas accessible' },
	{ value: 'WAS_EQUIPPED', label: 'Était équipé' }
] as const;

export type LogisticStatusType = (typeof statusOptions)[number]['value'];
