// REACT
import { useCallback, useEffect, useState } from 'react';
// CSS
import 'react-html5-camera-photo/build/css/index.css';
import { useHistory } from 'react-router';

// CONTEXT
import { useSensorContext } from '../../components/Context/Context';
// COMPONENTS
import { resizeReactCameraPicture } from '../../lib/Helper/PhotoHelper';
import { Camera } from './Photo/Camera';
import { generateImageName, saveToMinioBucket } from './Photo/MinioClient';
import { PhotoError } from './Photo/PhotoError';
import { PhotoHome } from './Photo/PhotoHome';
import { PhotoValidation } from './Photo/PhotoValidation';
import { PhotoWrapper } from './Photo/PhotoWrapper';
import { LAST_PHOTO_SAVED } from './Photo/model';
import './WizardPhoto.scss';

export default function WizardPhoto() {
	const history = useHistory();
	const [cameraMode, setCameraMode] = useState<
		'INIT' | 'CAMERA' | 'CAMERA_ON' | 'ERROR' | 'VALIDATION'
	>('INIT');

	const [photoUri, setPhotoUri] = useState<string | null>(null);
	const [error, setError] = useState<{ name: string }>();

	const sensorContext = useSensorContext();

	const [loading, setLoading] = useState(false);

	const onExistingPhotoSelect = (uri: string) => {
		setPhotoUri(uri);
		sensorContext.setSensor({
			...sensorContext.sensor,
			container: {
				...sensorContext.sensor.container,
				picture: uri
			}
		});
		history.push('/wizard/geolocation');
	};
	const savePhotoInAzure = async () => {
		setLoading(true);
		if (!loading) {
			const name = generateImageName(
				sensorContext.sensor.organizationObject.id
			);
			await saveToMinioBucket(photoUri!, name, () => {
				const url = `${process.env.REACT_APP_HEY_MEDIA_API}/${name}`;
				sensorContext.setSensor({
					...sensorContext.sensor,
					container: {
						...sensorContext.sensor.container,
						picture: url
					}
				});
				localStorage.setItem(LAST_PHOTO_SAVED, url);
				setLoading(false);
				history.push('/wizard/geolocation');
			});
		}
	};

	const resizeImage = useCallback(resizeReactCameraPicture, []);

	useEffect(() => {
		navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
			stream.getTracks().forEach(function (track) {
				track.stop();
			});
		});
	}, []);

	return (
		<>
			{cameraMode === 'ERROR' && (
				<PhotoError
					error={error!}
					onRetry={() => {
						setError(undefined);
						setCameraMode('INIT');
					}}
				/>
			)}
			<PhotoWrapper hidden={cameraMode !== 'CAMERA_ON'}>
				<Camera
					onSave={async (dataUri) => {
						const resize = await resizeImage(dataUri);

						setPhotoUri(resize);
						setCameraMode('VALIDATION');
					}}
					onCancel={() => {
						setCameraMode('INIT');
					}}
					onError={setError}
				/>
			</PhotoWrapper>
			{cameraMode === 'VALIDATION' && (
				<PhotoValidation
					photoUri={photoUri!}
					validating={loading}
					onValidate={savePhotoInAzure}
					onRetry={() => {
						setCameraMode('CAMERA_ON');
					}}
				/>
			)}
			{cameraMode === 'INIT' && (
				<PhotoHome
					onPhotoUpload={async (dataUri) => {
						const resize = await resizeImage(dataUri);
						setPhotoUri(resize);
						setCameraMode('VALIDATION');
					}}
					loading={loading}
					onExistingPhotoSelect={onExistingPhotoSelect}
					onNext={() => setCameraMode('CAMERA_ON')}
				/>
			)}
		</>
	);
}
