import { Option } from '../../../../../api/model';
import { ApiGetDepositPoint } from '../../../../../api/model/container';

export type OptionWithAddress = Option & { address: string };
export const convertDepositPointToOption = (
	depositPoint?: ApiGetDepositPoint
): OptionWithAddress | undefined => {
	if (!depositPoint) {
		return;
	}
	return {
		value: depositPoint['@id'],
		label: depositPoint.name,
		address: depositPoint.address
	};
};
