import React, {
	KeyboardEvent,
	KeyboardEventHandler,
	useEffect,
	useState
} from 'react';
import { useHistory } from 'react-router';

import { getOrganizations } from '../api';
import { FOUND, NOTFOUND } from '../api/model';
import { useSensorContext } from '../components/Context/Context';
import {
	Organization as OrganizationType,
	SensorContextType
} from '../components/Context/ContextType';
import Loading from '../components/FabButton/Loading/Loading';
import Header from '../components/Header/Header';
import { Button } from '../lib/Buttons/Button';
import { InputText } from '../lib/Forms/InputText/InputText';
import './Organization.scss';

export default function Organization() {
	const sensorContext: SensorContextType = useSensorContext();
	const history = useHistory();
	const isComingFromLoginPage = history.location.search.includes('login=true');

	const [organizations, setOrganizations] = useState<OrganizationType[]>([]);
	const [nextUrl, setNextUrl] = useState<string>('/v2/organizations');
	const [status, setStatus] = useState<string>(NOTFOUND);
	const [filter, setFilter] = useState<string | undefined>();

	const getData = (nextUrl: string) => {
		getOrganizations(nextUrl).then(({ organizations: data, next }) => {
			setStatus(FOUND);
			const ids: string[] = [];
			const filterTwins = [
				...organizations,
				...data,
				...data.flatMap((o: OrganizationType) => o.children)
			].filter((o: OrganizationType) => {
				if (!ids.includes(o.id)) {
					ids.push(o.id);
					return true;
				}
				return false;
			});
			setOrganizations(filterTwins);
			setNextUrl(next);
		});
	};

	useEffect(
		() => {
			if (nextUrl) {
				getData(nextUrl);
			}
		}, // eslint-disable-next-line
		[nextUrl]
	);

	const setUserOrganization = (organization: OrganizationType) => {
		sensorContext.setOrganization(organization['@id']);
		sensorContext.setUserOrganization(organization);
		history.push('/search');
	};

	const onKeyDown: KeyboardEventHandler<HTMLInputElement> = ({
		target
	}: KeyboardEvent<HTMLInputElement>) => {
		const value = (target as HTMLInputElement)?.value;
		setFilter(value.toLowerCase());
	};

	const filtered = organizations.filter(
		(o) => !filter || o?.name.toLowerCase().indexOf(filter) >= 0
	);
	const sorted = filtered.sort((o1, o2) =>
		o1.name.toLowerCase().localeCompare(o2.name.toLowerCase())
	);

	if (isComingFromLoginPage && status === FOUND) {
		const organization = sorted.find(
			(o) => o['@id'] === sensorContext.getOrganization()
		);
		if (!organization) {
			localStorage.removeItem('user_organization');
		}
	}
	return (
		<>
			<Header />
			<div id="Search" className="page Organization">
				{isComingFromLoginPage ? (
					<h2>Choisir une organisation</h2>
				) : (
					<h2>Changer d'organisation</h2>
				)}

				<div className="container-model-filter">
					<InputText
						placeholder="Nom de l'organisation"
						type="text"
						classModifiers={[]}
						onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
					/>
				</div>
				{status === NOTFOUND && <Loading />}
				{status === FOUND && (
					<ul id="searchResult" className="SearchResult">
						{sorted
							.sort((o1, o2) => o1.name.localeCompare(o2.name))
							.map((organization) => {
								return (
									<li key={organization['@id']}>
										<h3 className="cardTitle">
											{organization.name}{' '}
											{sensorContext.getOrganization() === organization['@id']
												? '(organisation courante)'
												: ''}
										</h3>
										<Button
											type="button"
											label="Sélectionner"
											classModifiers={['small']}
											onClick={() => setUserOrganization(organization)}
										/>
									</li>
								);
							})}
					</ul>
				)}
			</div>
		</>
	);
}
