import { MouseEvent } from 'react';

import Loading from '../../../../components/FabButton/Loading/Loading';
import { useFlows } from '../ContainerForm/flows/useFlows';
import { isPav } from '../isPav';
import './ContainerList.scss';
import { ContainerListItem } from './ContainerListItem';
import { PavOrContainer } from './groupContainerByPav';

type ContainerListTypes = {
	containers?: PavOrContainer[];
	onSelect: (container: any) => void;
};

export const Containers = ({
	containers,
	onSelect
}: Readonly<ContainerListTypes>) => {
	const flows = useFlows();

	if (!containers) {
		return <Loading />;
	}
	return (
		<ul id="containers_list">
			{containers?.map((container) => {
				if (isPav(container)) {
					return (
						<li key={container.id} className="container_list_pav_item">
							<ContainerListItem container={container} flows={flows}>
								<Containers
									onSelect={onSelect}
									containers={container.containers}
								/>
							</ContainerListItem>
						</li>
					);
				}

				return (
					<li
						className="container_list_container_item"
						key={container.id}
						role="button"
						tabIndex={0}
						onClick={(e: MouseEvent) => {
							e.stopPropagation();
							onSelect(container);
						}}
					>
						<ContainerListItem container={container} flows={flows} />
					</li>
				);
			})}
		</ul>
	);
};
