import { captureException, setContext } from '@sentry/react';
import dayjs from 'dayjs';

import { isAccessTokenValid, refreshToken } from './authentication';
import { clearLocalStorage } from './clearLocalStorage';
import { FetchArguments } from './model';
import { fetchAndTrack } from './tracker';
import { commonHeaders } from './utils';

export const fetchWithAuthentication = (url: string, options = {}) => {
	const fetchArguments = (): FetchArguments => [
		url,
		{ ...options, cache: 'no-store', headers: { ...commonHeaders() } }
	];

	let promise;

	setContext('fetchWithAuthentication', {
		accessExpireAt: localStorage.getItem('access_expire_at'),
		accessExpireAtValid: dayjs().isBefore(
			dayjs(parseInt(localStorage.getItem('access_expire_at')!) * 1000)
		),
		url
	});

	if (isAccessTokenValid()) {
		const [url, options, name] = fetchArguments();
		promise = fetchAndTrack(url, options, name);
	} else {
		promise = refreshToken().then(() => {
			const [url, options, name] = fetchArguments();
			return fetchAndTrack(url, options, name);
		});
	}

	return promise
		.then((response: Response) => {
			if (response.status >= 400) {
				//We want to keep the 403 into sentry
				if (response.status === 403)
					captureException('fetchWithAuthenticationError' + response.status, {
						contexts: {
							response,
							fetchArguments
						} as any
					});

				const error: Error & { status?: number } = new Error(
					'fetchWithAuthenticationError ' + response.status
				);
				error.status = response.status;
				throw error;
			}

			return response.json();
		})
		.catch((e) => {
			//Only errors 401,403 must return the user to home. Don't forger to log the 403 Exception into sentry
			if (e.status === 401) {
				clearLocalStorage();
				window.location.pathname = '/login';
			}
		});
};
