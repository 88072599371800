import { ApiGetComment } from '../../../api/model/comment';

const CommentCardImage = ({
	comment
}: Readonly<{
	comment: ApiGetComment;
}>) => {
	const temporaryImage = comment.picture?.startsWith('data');

	return (
		<img
			className={temporaryImage ? 'loading' : ''}
			width="96px"
			height="120px"
			src={
				temporaryImage
					? comment.picture
					: `${comment.picture}?width=96&height=120`
			}
			alt=""
		/>
	);
};

const DateFormatter = new Intl.DateTimeFormat('fr');

export const CommentDate = ({ date }: Readonly<{ date: string }>) => {
	if (!date) {
		return null;
	}
	return <p>{DateFormatter.format(new Date(date))}</p>;
};
export const CommentCard = ({
	comment
}: Readonly<{
	comment: ApiGetComment;
}>) => {
	return (
		<>
			{!comment['@id'] && <p>loading </p>}
			{comment.picture && <CommentCardImage comment={comment} />}
			<p>{comment.content}</p>
			<p>
				{comment.author?.firstName} {comment.author?.lastName}
			</p>
			<CommentDate date={comment.createdAt} />
		</>
	);
};
