import pThrottle from 'p-throttle';
import { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';

import { getPlacesFromAzure } from '../../../api';

const throttle = pThrottle({
	limit: 2,
	interval: 300
});

const getPlacesFromAzureThrottled = throttle(
	(searchValue: string) => getPlacesFromAzure(searchValue) as Promise<unknown[]>
);

type AddressFilterTypes = {
	value: string;
	onChange: (value: string) => void;
	onSelect: (value: string, coordinates: [number, number]) => void;
};

export const AddressFilter = ({
	value,
	onChange,
	onSelect
}: Readonly<AddressFilterTypes>) => {
	const [searchResults, setSearchResults] = useState<unknown[]>([]);

	useEffect(() => {
		if (value) {
			getPlacesFromAzureThrottled(value).then((places: unknown[]) =>
				setSearchResults(places ?? [])
			);
		}
	}, [value]);

	return (
		<div className="search-bar">
			<Autocomplete
				getItemValue={(item) => item.address.freeformAddress}
				items={searchResults}
				renderItem={(item) => {
					return (
						<div className="search-bar__item">
							{item.address.freeformAddress}
						</div>
					);
				}}
				renderMenu={(items, value, style) => {
					return (
						<div
							style={{ ...style }}
							className="search-bar__menu"
							children={items}
						/>
					);
				}}
				wrapperProps={{ className: 'search-bar__wrapper' }}
				inputProps={{
					placeholder: "exemple: 13a Quai d'Ille et Rance, 35000 Rennes"
				}}
				value={value}
				onChange={(e) => onChange(e.target.value)}
				onSelect={(label, item) => {
					const coordinates = [item.position.lat, item.position.lon] as [
						number,
						number
					];
					onSelect(label, coordinates);
				}}
			/>
		</div>
	);
};
