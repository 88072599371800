import { ChangeEvent } from 'react';

import './PhotoBottomSheet.scss';
import { LAST_PHOTO_SAVED } from './model';

type PhotoBottomSheetTypes = {
	open?: boolean;
	onPass?: () => void;
	onFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
	onNewPhoto: () => void;
	onLastPhotoSelect: (image: string) => void;
	onValidate?: () => void;
};

export const PhotoBottomSheet = ({
	open,
	onPass,
	onFileUpload,
	onNewPhoto,
	onLastPhotoSelect,
	onValidate
}: Readonly<PhotoBottomSheetTypes>) => {
	if (!open) {
		return null;
	}

	const lastPhotoSaved = localStorage.getItem(LAST_PHOTO_SAVED);

	return (
		<div className="bottom-sheet-wrapper show-modal">
			<div className="backdrop"></div>
			<div className="bottom-sheet">
				{lastPhotoSaved && <img src={lastPhotoSaved + '?width=100'} alt="" />}
				<ul>
					<li>
						<button type="button" onClick={onNewPhoto}>
							Prendre une nouvelle photo
						</button>
					</li>
					<li>
						<label>
							<input type="file" accept="image/*" onChange={onFileUpload} />
							Choisir une photo
						</label>
					</li>
					{lastPhotoSaved && (
						<li>
							<button
								type="button"
								onClick={() => {
									onLastPhotoSelect(lastPhotoSaved);
								}}
							>
								Utiliser la dernière prise
							</button>
						</li>
					)}
					{onValidate && (
						<li>
							<button type="button" onClick={onValidate}>
								Sans image
							</button>
						</li>
					)}
					{onPass && (
						<li>
							<button type="button" onClick={onPass}>
								Passer
							</button>
						</li>
					)}
				</ul>
			</div>
		</div>
	);
};
