import React from 'react';

import { classWithModifiers } from '../../lib/utils/css';
import { statusOptions } from '../../pages/wizard/Container/ContainerForm/status/statusOptions';
import Icon from '../Icons/Icon';

export type TagType =
	| 'container-broken'
	| 'container-damaged'
	| 'not-found'
	| 'container-no-network'
	| 'sensor-wrong-measure'
	| 'sensor-no-comm';
type ContainerTagProps = {
	tags: string[];
};
const getLabelForTag = (tags: string): string => {
	if (!tags) {
		return '';
	}
	if (tags === 'container-broken') {
		return 'Contenant SAV';
	}
	if (tags === 'container-damaged') {
		return 'Contenant endommagé';
	}
	if (tags === 'not-found') {
		return 'Contenant introuvable';
	}
	if (tags === 'container-no-network') {
		return 'Contenant sans réseau';
	}
	if (tags === 'sensor-wrong-measure') {
		return 'Sensor à nettoyer';
	}
	if (tags === 'sensor-no-comm') {
		return 'Sensor ne communique pas';
	}
	return '';
};

export const ContainerTag = ({ tags = [] }: ContainerTagProps) => {
	return (
		<>
			{tags
				.map((tag) => getLabelForTag(tag))
				.filter((label) => label !== '')
				.map((label) => (
					<Tag
						key={label}
						label={label}
						classModifiers={['default-status']}
						styles={{
							color: 'white',
							backgroundColor: '#69d9bf'
						}}
					/>
				))}
		</>
	);
};

type StatusTagProps = {
	logisticStatus?: string;
};
const getLabelForLogisticStatus = (logisticStatus: string): string => {
	if (!logisticStatus) {
		return '';
	}
	const option = statusOptions.find(
		(option) => option.value.toLowerCase() === logisticStatus.toLowerCase()
	);
	if (!!option) {
		return option.label;
	}
	return '';
};
export const StatusTag = ({ logisticStatus }: StatusTagProps) => {
	if (!logisticStatus) {
		return <></>;
	}

	const label = getLabelForLogisticStatus(logisticStatus);

	if (label === '') {
		return null;
	}
	return <Tag label={label} />;
};

type TagProps = {
	label: string;
	classModifiers?: string[];
	iconLeft?: any;
	styles?: { color: string; backgroundColor: string };
};

const Tag = ({ label, classModifiers, iconLeft, styles }: TagProps) => {
	return (
		<div
			style={styles ?? {}}
			className={classWithModifiers('hl-tag', [...(classModifiers || [])])}
		>
			{iconLeft && (
				<Icon
					fill={styles?.color ?? ''}
					className="hl-tag__left-icon"
					iconPath={iconLeft}
				/>
			)}
			<div className="hl-tag__label">{label}</div>
		</div>
	);
};

export default Tag;
