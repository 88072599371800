import { Sensor, SensorMeasure } from '../../../components/Context/ContextType';
import { SignalStrength } from '../../../components/SignalStrength';
import { isDefined } from '../../../lib/Helper/ObjectHelper';
import { Measure } from '../measure';

type SensorCardLastMeasureItemProps = {
	measure: SensorMeasure | undefined;
	term: string;
	placeholder: string;
};
const SensorCardLastMeasureItem = ({
	measure,
	term,
	placeholder
}: SensorCardLastMeasureItemProps) => {
	if (!isDefined(measure)) {
		return null;
	}

	return (
		<>
			<dt>{term}</dt>
			<dl>
				<SignalStrength
					signalStrength={measure.signalStrength}
					rssi={measure.rssi}
					snr={measure.snr}
				/>
			</dl>
			<Measure measure={measure} messageIfUndefined={placeholder}></Measure>
		</>
	);
};

export const SensorCardLastMeasure = ({ sensor }: { sensor: Sensor }) => {
	let lastPeriodicalMeasure = sensor.monitor?.lastPeriodicalMeasure;
	let lastFastMeasure = sensor.monitor?.lastFastMeasure;
	let lastPeriodicalTerm = 'Mesure périodique:';

	if (
		(isDefined(lastFastMeasure) &&
			isDefined(lastPeriodicalMeasure) &&
			lastFastMeasure.date > lastPeriodicalMeasure?.date) ||
		!isDefined(lastPeriodicalMeasure)
	) {
		lastPeriodicalMeasure = lastFastMeasure;
		lastPeriodicalTerm = 'Mesure périodique (FastMode) :';
	}

	return (
		<dl>
			<SensorCardLastMeasureItem
				measure={sensor.monitor?.lastManualMeasure}
				term="Mesure manuelle:"
				placeholder="Aucune mesure manuelle"
			/>

			<SensorCardLastMeasureItem
				measure={lastPeriodicalMeasure}
				term={lastPeriodicalTerm}
				placeholder="Aucune mesure périodique"
			/>

			<SensorCardLastMeasureItem
				measure={sensor.monitor?.lastFirstUplink}
				term="'First Uplink':"
				placeholder="Aucun First Uplink"
			/>
		</dl>
	);
};
