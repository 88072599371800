import React from 'react';
import { useHistory } from 'react-router';

import { Cover } from '../../components/Animation/Animation';
import WizardFooter from '../../components/Footer/WizardFooter';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import './WizardFixation.scss';

export default function WizardFixation() {
	const history = useHistory();
	return (
		<div id="Fixation" className="page wizard-page">
			<Cover path={'/images/big/fixation'} />
			<div className="wizard-page__content">
				<h2>Fixation</h2>
				<p>Veuillez fixer le capteur sur le contenant.</p>
			</div>
			<WizardStepper activeIndex={5} />
			<WizardFooter
				next={() => {
					history.push('/wizard/measure?measure=indoor');
				}}
				previous={() => {
					history.goBack();
				}}
			/>
		</div>
	);
}
