// REACT
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import './GenericGeolocationMap.scss';
// CONTEXT/API
import { getCurrentPosition } from '../../../api/geolocation';
//COMPONENTS
import {
	createIcon,
	createMapController,
	createMarker,
	initMap,
	setMarkers
} from '../../../lib/Helper/GeolocHelper';
import { AddressFilter } from './AddressFilter';
import './GenericGeolocationMap.scss';
import { KeepBottomToolbar } from './KeepBottomToolbar';
import { UpdateBottomToolbar } from './UpdateBottomToolbar';

let userPositionMarker;
let containerPositionMarker;
let newPositionMarker;
let steadyGroupMarkers;
let map;

const geolocalisationErrorCallback = console.error;
const geolocalisationOptions = {
	enableHighAccuracy: true,
	maximumAge: 0
};

export const GenericGeolocationMap = ({
	children = <></>,
	onKeepCoordinates,
	onUpdateCoordinates,
	container,
	onCancel,
	saving = false,
	fromContainerForm = false,
	fromContainerUpdateForm = false
}) => {
	const watch = useRef();
	const [center, setCenter] = useState([]);

	const [updateMode, setUpdateMode] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	// action on container marker click
	const updateContainerPosition = useRef(() => {
		setUpdateMode(true);
	});

	useEffect(() => {
		if (updateMode) {
			document
				.getElementsByClassName('container-marker__position')[0]
				.classList.remove('container-marker__position--animated');

			// set new Marker and attribute geoloc everytime map moves
			newPositionMarker = createMarker(
				[container.latitude, container.longitude],
				createIcon('/images/new-map-point.svg', [22, 55], {
					className: 'temporary-marker__position'
				})
			).addTo(map);

			map.on('move', function () {
				const center = map.getCenter();
				newPositionMarker.setLatLng(center);
				setCenter(center);
			});

			map.setView([container.latitude, container.longitude], 18);
		}
	}, [updateMode, container.latitude, container.longitude]);

	const setupMap = (
		map,
		position,
		selectedContainer,
		updateContainerPosition
	) => {
		if (!map) {
			return;
		}

		selectedContainer.latitude =
			selectedContainer.latitude || position.coords.latitude;
		selectedContainer.longitude =
			selectedContainer.longitude || position.coords.longitude;

		userPositionMarker = createMarker(
			[position.coords.latitude, position.coords.longitude],
			createIcon('/images/user_position.png', [22, 23], { iconSize: [40, 40] })
		);

		containerPositionMarker = createMarker(
			[selectedContainer.latitude, selectedContainer.longitude],
			createIcon('/images/map-point.svg', [23, 55], {
				className:
					'container-marker__position container-marker__position--animated'
			})
		);

		steadyGroupMarkers = setMarkers(
			[userPositionMarker, containerPositionMarker],
			map
		);

		map.fitBounds(steadyGroupMarkers.getBounds());

		containerPositionMarker.on('click', updateContainerPosition.current);

		// set new center on user position icon and modify zoom controll position
		map.zoomControl.setPosition('bottomright');
		window.L.Control.CurrentPosition = createMapController(map, (ev) => {
			window.L.DomEvent.stopPropagation(ev);
			window.L.DomEvent.preventDefault(ev);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					userPositionMarker.setLatLng([
						position.coords.latitude,
						position.coords.longitude
					]);
					map.panTo(userPositionMarker.getLatLng(), 18);
				},
				geolocalisationErrorCallback,
				geolocalisationOptions
			);
		});

		window.L.control.currentPosition = function (opts) {
			return new window.L.Control.CurrentPosition(opts);
		};

		window.L.control.currentPosition({ position: 'bottomright' }).addTo(map);

		watch.current = navigator.geolocation.watchPosition(
			(position) => {
				userPositionMarker.setLatLng([
					position.coords.latitude,
					position.coords.longitude
				]);
			},
			geolocalisationErrorCallback,
			geolocalisationOptions
		);
	};
	useLayoutEffect(() => {
		getCurrentPosition().then((position) => {
			console.log(
				'[WizardGeolocalisation.js::getCurrentPosition] (line #157)]',
				position
			);
			try {
				map = initMap();
				setupMap(map, position, container, updateContainerPosition);
			} catch (e) {
				console.warn(
					'[WizardGeolocalisation.js::getCurrentPosition] (line #162)] The Map is not initialized'
				);
			}
		});
		return () => {
			if (watch.current) {
				navigator.geolocation.clearWatch(watch.current);
			}
		};
	}, [container, updateContainerPosition]);

	return (
		<div
			id="Geolocalisation"
			className={`${fromContainerForm ? '' : 'page dynamic-height'}`}
		>
			{children}
			<div
				id="SearchBlock"
				className={`container-geolocation ${!fromContainerForm ? 'fullscreen' : ''}`}
			>
				{!fromContainerForm && (
					<h2>
						{updateMode ? "Affiner l'emplacement" : 'Emplacement du contenant'}
					</h2>
				)}
				{updateMode && (
					<AddressFilter
						onSelect={(value, coordinates) => {
							setSearchValue(value);
							newPositionMarker.setLatLng(coordinates);
							setCenter(coordinates);
							map.fitBounds([[coordinates]]).setZoom(17);
						}}
						onChange={setSearchValue}
						value={searchValue}
					/>
				)}
				<div
					id="map"
					className={`${fromContainerForm ? '' : 'with-margin-bottom'}`}
				></div>
			</div>

			{updateMode && (
				<UpdateBottomToolbar
					onCancel={() => {
						setUpdateMode(false);
						map.removeLayer(newPositionMarker);
						map.fitBounds(steadyGroupMarkers.getBounds());
						document
							.getElementsByClassName('container-marker__position')[0]
							.classList.add('container-marker__position--animated');
					}}
					onSave={() => onUpdateCoordinates(center)}
					container={container}
				></UpdateBottomToolbar>
			)}

			{!updateMode && (
				<KeepBottomToolbar
					fromContainerForm={fromContainerForm}
					fromContainerUpdateForm={fromContainerUpdateForm}
					saving={saving}
					onCancel={onCancel}
					onNext={(step) => onKeepCoordinates(container, step)}
				></KeepBottomToolbar>
			)}
		</div>
	);
};
