import { PropsWithChildren, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { revokeAuthentication } from '../../api/authentication';
import ArrowLeft from '../../svg/ArrowLeft';
import { useSensorContext } from '../Context/Context';
import { SensorContextType } from '../Context/ContextType';
import './header.scss';

export const HeaderTitleWithBackButton = ({
	children,
	onCancel
}: Readonly<PropsWithChildren<{ onCancel?: () => void }>>) => {
	const history = useHistory();

	return (
		<header id="HeaderTitleWithBackButton">
			<button
				type="button"
				onClick={() => (onCancel ? onCancel() : history.goBack())}
				aria-label="Précédent"
			>
				<ArrowLeft />
			</button>
			<h2>{children} </h2>
		</header>
	);
};

export default function Header() {
	const sensorContext: SensorContextType = useSensorContext();
	const [isOpen, setIsOpen] = useState(false);
	const history = useHistory();
	const logout = () => {
		revokeAuthentication().then(() => {
			sensorContext.setSuperInstaller(undefined);
			history.replace('/login');
		});
	};

	return (
		<header id="Header">
			<Menu isOpen={isOpen} onStateChange={(state) => setIsOpen(state.isOpen)}>
				<ul onClick={() => setIsOpen(false)}>
					<li>
						<Link className="menu-item" to="/search">
							Installation
						</Link>
					</li>
					{(sensorContext.isSuperInstaller() ||
						sensorContext.hasAccessToMulipleOrgs()) && (
						<li>
							<Link className="menu-item" to="/organization">
								Changer d'organisation
							</Link>
						</li>
					)}
					<li>
						<Link className="menu-item" to="/containers-list">
							Liste de contenants
						</Link>
					</li>
					<li>
						<Link className="menu-item" to="/faq">
							FAQ
						</Link>
					</li>
					<li>
						<Link className="menu-item" to="/installs">
							Rapports
						</Link>
					</li>
					<li>
						<button className="menu-item" type="button" onClick={logout}>
							Se déconnecter
						</button>
					</li>
				</ul>
			</Menu>
			<div>
				<h1>Heyliot</h1>
				<p>{sensorContext.getUserOrganizationName()}</p>
			</div>
		</header>
	);
}
