import { useContext } from 'react';

import WizardFooter from '../../../components/Footer/WizardFooter';
import NextButton from '../../../components/NextButton/NextButton';
import WizardStepper from '../../../components/WizardStepper/WizardStepper';
import { OriginCreateContainerContext } from '../Container/ContainerForm/OriginCreateContainerContext';

type KeepBottomToolbarTypes = {
	onNext: (step?: 'create-and-install') => void;
	onCancel: () => void;
	saving?: boolean;
	fromContainerForm?: boolean;
	fromContainerUpdateForm?: boolean;
};
export const KeepBottomToolbar = ({
	onNext,
	onCancel,
	saving,
	fromContainerForm,
	fromContainerUpdateForm
}: Readonly<KeepBottomToolbarTypes>) => {
	const sensorContext = useContext(OriginCreateContainerContext);

	/**
	 * Footer when we are on the Historical geolocation Page
	 */
	if (!fromContainerForm) {
		return (
			<>
				<WizardStepper activeIndex={8} />
				<WizardFooter previous={onCancel} next={onNext} nextLabel="conserver" />
			</>
		);
	}

	return (
		<>
			{!fromContainerForm && <WizardStepper activeIndex={8} />}
			<WizardFooter
				previous={onCancel}
				next={onNext}
				nextLabel="Valider et retour"
				disabledNext={saving}
				withBackButton={!sensorContext?.fromWizard || !!fromContainerUpdateForm}
			>
				{sensorContext?.fromWizard && !fromContainerUpdateForm && (
					<NextButton
						disabled={saving}
						next={() => onNext('create-and-install')}
						label="Valider et installer"
					/>
				)}
			</WizardFooter>
		</>
	);
};
