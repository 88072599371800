import React, { ComponentProps, PropsWithChildren } from 'react';

import { classWithModifiers } from '../../utils/css';

type InputTextProps = {
	label?: string;
	message?: string;
	classModifiers?: string[];
} & ComponentProps<'input'>;

export const InputText = ({
	label,
	message,
	classModifiers,
	...props
}: InputTextProps & LabelTypes) => {
	return (
		<Label label={label} classModifiers={classModifiers}>
			<input {...props} autoComplete="off" className="hl-input-text__input" />
			{message && <div className="hl-input-text__message">{message}</div>}
		</Label>
	);
};

type LabelTypes = {
	label?: string;
	classModifiers?: string[];
};
export const Label = ({
	children,
	label,
	classModifiers
}: PropsWithChildren<LabelTypes>) => {
	return (
		<label
			className={classWithModifiers('hl-input-text', [
				...(classModifiers || [])
			])}
		>
			<div className="hl-input-text__label">{label}</div>
			{children}
		</label> // ---
	);
};
