import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CommentModal from '../../../components/CommentModal/CommentModal';

type GeolocCommentModalTypes = {
	loading: boolean;
	defaultValue: string;
	onValidate: (comment: string | null) => void;
	onCancel: () => void;
};

export const GeolocCommentModal = ({
	defaultValue,
	onValidate,
	onCancel,
	loading
}: Readonly<GeolocCommentModalTypes>) => {
	const history = useHistory();
	const [addressComment, setAddressComment] = useState(defaultValue ?? '');

	return (
		<CommentModal
			title="Commentaire sur l'emplacement"
			instructions="Voulez-vous préciser des informations complémentaires à l'emplacement ?"
			cancel={onCancel}
			ok={() => {
				onValidate(
					addressComment != null && !addressComment.replace(/\s/g, '').length
						? null
						: addressComment
				);
				history.push('/wizard/summary');
			}}
			pass={true}
			passAction={() => {
				history.push('/wizard/summary');
			}}
			loader={loading}
		>
			<textarea
				className="comment-modal__textarea"
				value={addressComment}
				onChange={(event) => setAddressComment(event.target.value)}
				placeholder="exemple: le contenant se trouve à gauche du poteau électrique."
			/>
		</CommentModal>
	);
};
