import React from 'react';
import { useHistory } from 'react-router';

import WizardFooter from '../../../components/Footer/WizardFooter';
import RetryButton from '../../../components/RetryButton/RetryButton';
import WizardStepper from '../../../components/WizardStepper/WizardStepper';

type PhotoValidationTypes = {
	photoUri: string;
	validating: boolean;
	onValidate: () => void;
	onRetry: () => void;
};
export const PhotoValidation = ({
	photoUri,
	validating,
	onValidate,
	onRetry
}: Readonly<PhotoValidationTypes>) => {
	const history = useHistory();
	return (
		<>
			<div className="picture-render">
				<img id="taken-picture" src={photoUri} alt="" />
			</div>
			<div id="PhotoOff" className="page Visualisation wizard-page">
				<div className="wizard-page__content wizard-page__content_small">
					<h2>Satisfait ?</h2>
					<p>Etes-vous satisfait de votre photo ?</p>
					<RetryButton onClick={onRetry} />
				</div>
				<WizardStepper activeIndex={6} />
				<WizardFooter
					previous={() => history.goBack()}
					next={onValidate}
					disabledNext={validating}
					loader={validating}
				/>
			</div>
		</>
	);
};
