import { CREATE_COMMENT, host } from './configuration';
import { ClientSideApiPayload } from './model';
import { ApiGetComment, ApiPutPostCommentBody } from './model/comment';
import { fetchAndTrack } from './tracker';
import { commonHeaders, fetchAll, fetchOnePage } from './utils';

const generateCommentsUrl = (containerId: string): string => {
	return `/v2/containers/${containerId}/comments`;
};

function getComments(
	containerId: string,
	url = generateCommentsUrl(containerId)
): Promise<ClientSideApiPayload<ApiGetComment>> {
	return fetchOnePage(`${host}${url}`);
}

export const getAllComments = fetchAll(getComments);

export const addComments = (
	containerId: string,
	body: ApiPutPostCommentBody
): Promise<ApiGetComment> => {
	return fetchAndTrack(
		`${host}/v2/containers/${containerId}/comments`,
		{
			method: 'POST',
			headers: {
				...commonHeaders(),
				'content-type': 'application/ld+json'
			},
			body: JSON.stringify(body)
		},
		CREATE_COMMENT
	).then((response) => response.json());
};
