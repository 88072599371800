import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Cover } from '../../components/Animation/Animation';
import BackButton from '../../components/BackButton/BackButton';
import { useSensorContext } from '../../components/Context/Context';
import { Sensor } from '../../components/Context/ContextType';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import NextButton from '../../components/NextButton/NextButton';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import { Button } from '../../lib/Buttons/Button';
import { InputText } from '../../lib/Forms/InputText/InputText';
import './WizardContainerValidator.scss';

const defaultValue = {
	CONTAINER_EMPTY_MEASURE: parseInt(
		process.env.REACT_APP_CONTAINER_EMPTY_MEASURE!
	),
	CONTAINER_FULL_MEASURE: parseInt(
		process.env.REACT_APP_CONTAINER_FULL_MEASURE!
	)
};

const getUpdateValue = (key: string, sensor: Sensor) =>
	key === 'CONTAINER_EMPTY_MEASURE'
		? sensor.updateContainerMeasure?.empty
		: sensor.updateContainerMeasure?.full;
export const getMeasure = (sensor) => (key) =>
	getUpdateValue(key, sensor) ??
	sensor.container?.properties?.[key] ??
	defaultValue[key];

export default function ContainerValidator() {
	const throwError = useAsyncError();
	const [editMode, setEditMode] = useState(false);
	const sensorContext = useSensorContext();
	const getMeasureForSensor = getMeasure(sensorContext.sensor);

	const [emptyMeasure, setEmptyMeasure] = useState<number>(
		getMeasureForSensor('CONTAINER_EMPTY_MEASURE')
	);
	const [fullMeasure, setFullMeasure] = useState<number>(
		getMeasureForSensor('CONTAINER_FULL_MEASURE')
	);

	const history = useHistory();

	const cancelUpdate = () => {
		setEmptyMeasure(getMeasureForSensor('CONTAINER_EMPTY_MEASURE'));
		setFullMeasure(getMeasureForSensor('CONTAINER_FULL_MEASURE'));
		setEditMode(false);
	};

	const onNext = () => {
		if (editMode) {
			sensorContext.setSensor({
				...sensorContext.sensor,
				updateContainerMeasure: {
					empty: emptyMeasure,
					full: fullMeasure
				}
			});
		}
		history.push('/wizard/fixation');
	};

	const onUnknownMeasure = () => {
		sensorContext.setSensor({
			...sensorContext.sensor,
			unknownContainerMeasure: true
		});
		history.push('/wizard/fixation');
	};

	const nextDisabled =
		editMode &&
		(emptyMeasure === '' ||
			fullMeasure === '' ||
			!(Number(emptyMeasure) > Number(fullMeasure)));

	useEffect(() => {
		if (nextDisabled) throwError('empty_measure_less_full_measure');
	}, [nextDisabled, throwError]);

	return (
		<div id="WizardValidation" className={'page wizard-page'}>
			<Cover path={'/images/big/measure_boundaries'} />

			<div className="wizard-page__content">
				<h2 className="wizard-page__content_title">Validation des bornes</h2>

				<div id="result">
					<InputText
						label="Mesure à vide"
						type="text"
						classModifiers={['full-width']}
						disabled={!editMode}
						value={emptyMeasure}
						onChange={(e) => setEmptyMeasure(e.target.value)}
					/>
					<InputText
						label="Mesure à plein"
						type="text"
						classModifiers={['full-width']}
						disabled={!editMode}
						value={fullMeasure}
						onChange={(e) => setFullMeasure(e.target.value)}
					/>
				</div>
				<div className="actions_buttons">
					{!editMode && (
						<>
							<Button
								label="Modifier"
								type="button"
								classModifiers={['white', 'border']}
								onClick={() => setEditMode(true)}
							/>
							<Button
								label="Je ne connais pas les bornes"
								type="button"
								classModifiers={['white']}
								onClick={onUnknownMeasure}
							/>
						</>
					)}
					{editMode && (
						<Button
							label={'Annuler les modifications'}
							type="button"
							classModifiers={['white']}
							onClick={cancelUpdate}
						/>
					)}
				</div>
			</div>

			<WizardStepper activeIndex={4} />

			<footer>
				<BackButton previous={history.goBack} />
				<NextButton disabled={nextDisabled} next={onNext} label="Je valide" />
			</footer>
		</div>
	);
}
