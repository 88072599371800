import React, { PropsWithChildren } from 'react';

import { ApiGetContainer, ApiGetFlow } from '../../../../api/model/container';
import { ICONS } from '../../../../components/Icons/icons';
import Tag, { ContainerTag, StatusTag } from '../../../../components/Tag';
import { ContainerFlowTag } from '../../../../components/Tag/ContainerFlowTag';
import { isPav } from '../isPav';
import { ActiveContractsList } from './ActiveContractsList';
import './ContainerListItem.scss';
import { PavWithContainers } from './groupContainerByPav';

type ContainerListItemProps = {
	container: ApiGetContainer | PavWithContainers;
	flows: ApiGetFlow[];
};
export const ContainerListItem = ({
	container,
	flows,
	children
}: Readonly<PropsWithChildren<ContainerListItemProps>>) => {
	if (isPav(container)) {
		return (
			<div className="container-list-item-block">
				<div className="container-list-item-header">
					<h3>{container.name}</h3>
				</div>
				<div className="container-list-item-body">{children}</div>
			</div>
		);
	}
	return (
		<div className="container-list-item-block">
			<div className="container-list-item-header">
				<h3>{container.name}</h3>
				{container.flow && (
					<ContainerFlowTag flow={container.flow} flows={flows!} />
				)}
			</div>
			<div className="container-list-item-body">
				{!!container.depositPoint && (
					<Tag
						label={container.depositPoint?.name}
						classModifiers={['default-status']}
						iconLeft={ICONS.CIRCLE}
					/>
				)}

				{container.logisticStatus === 'EQUIPPED' && (
					<ActiveContractsList container={container} />
				)}
				{container.logisticStatus !== 'EQUIPPED' && (
					<>
						<StatusTag logisticStatus={container.logisticStatus} />
						<ContainerTag tags={container.tags} />
					</>
				)}
			</div>
		</div>
	);
};
