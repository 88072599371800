import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

dayjs().locale('fr');

dayjs.extend(relativeTime);

export const DateFromNow = ({ date, locale, prefix }) => {
	const dateFromNow = dayjs(new Date(date)).locale(locale).fromNow();
	return (
		<p className="network--relativeDateTime">
			{prefix && `${prefix} `}
			{dateFromNow}{' '}
		</p>
	);
};
