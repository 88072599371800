import React, {
	PropsWithChildren,
	ReactElement,
	useCallback,
	useEffect
} from 'react';
import { RouteProps, useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { getOrganization, getUserHasAccessToMultipleOrgs } from '../../api';
import { getUserInfo, isAuthenticated } from '../../api/authentication';
import { useSensorContext } from '../../components/Context/Context';
import {
	Organization,
	SensorContextType
} from '../../components/Context/ContextType';

export const redirectIfNotAuthenticated = (
	page: ReactElement,
	redirectPage: string
) => (isAuthenticated() ? page : <Redirect to={redirectPage} />);

type WithUserRoleProps = {};
export const WithUserRole = ({
	children
}: PropsWithChildren<WithUserRoleProps>) => {
	const sensorContext: SensorContextType = useSensorContext();

	const setOrganization = useCallback(
		(organization: Organization) => {
			sensorContext.setUserOrganization(organization);
			sensorContext.setOrganization(organization?.['@id']);
		},
		[sensorContext]
	);

	const getUserOrganization = useCallback(
		(data) => {
			if (!!sensorContext.getOrganization()) {
				getOrganization(sensorContext.getOrganization()).then(
					(organization) => {
						setOrganization(organization);
					}
				);
			} else {
				setOrganization(data?.organization);
			}
		},
		[sensorContext, setOrganization]
	);

	const isSuperInstaller = sensorContext.isSuperInstaller();
	useEffect(() => {
		if (isSuperInstaller === undefined) {
			getUserInfo().then((data) => {
				sensorContext.setSuperInstaller(
					data?.profile?.['@id'] === '/v2/profiles/PROFILE_SUPER_INSTALLER'
				);
				getUserOrganization(data);
			});
		}
	}, [isSuperInstaller, sensorContext, getUserOrganization]);

	/**
	 * We need to fetch the number of organizations of the logged-in user in order to
	 * display the "Change Organization" in left-side menu.
	 */
	const accessToMulipleOrgs = sensorContext.hasAccessToMulipleOrgs();
	useEffect(() => {
		if (accessToMulipleOrgs === undefined) {
			getUserHasAccessToMultipleOrgs().then((bool) => {
				sensorContext.setAccessToMulipleOrgs(bool);
			});
		}
	}, [accessToMulipleOrgs, sensorContext]);
	return <>{children}</>;
};
export const PrivateRoute = ({
	Component,
	...rest
}: { Component: () => JSX.Element } & RouteProps) => {
	const location = useLocation();
	const sensorContext: SensorContextType = useSensorContext();
	const fromWizard = location.pathname.includes('/wizard/');
	const hasSensorSelected = !!sensorContext.sensor.serialNumber;

	return (
		<Route
			{...rest}
			render={(props: any) => {
				if (!isAuthenticated) {
					return <Redirect to="/login" />;
				}

				if (fromWizard && !hasSensorSelected) {
					return <Redirect to="/search" />;
				}
				return (
					<WithUserRole>
						<Component {...props} />
					</WithUserRole>
				);
			}}
		/>
	);
};
