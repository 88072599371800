import { useEffect, useLayoutEffect, useState } from 'react';

//context
import { hasDepositPoints } from '../../../../api/depositPoints';
import { Option } from '../../../../api/model';
import Loading from '../../../../components/FabButton/Loading/Loading';
import { createLogger } from '../../../../monitoring/logrocket';
//styles
import './ContainerForm.scss';
import { ContainerFormStep1 } from './ContainerFormStep1';
import { useOriginCreateContainerContext } from './OriginCreateContainerContext';
import { GenericGeolocationMap } from '../../Geolocation/GenericGeolocationMap';

const logger = createLogger('ContainerForm');

export type FormContainer = {
	name: string;
	group?: Option;
	model?: Option;
	flow?: Option;
	deposit?: Option;
	statut?: Option;
	tags?: string[];
	latitude?: number;
	longitude?: number;
};

type ContainerFormTypes = {
	container: FormContainer;
	setContainer: (container: FormContainer) => void;
	onSave?: (
		container: FormContainer,
		step?: 'create-and-install'
	) => Promise<void>;
	onUpdate?: (
		container: FormContainer,
		step?: 'create-and-install'
	) => Promise<void>;
	models: any[];
	groups: any[];
	flows: any[];
	tags?: boolean;
	onCancel?: () => void;
};
export const ContainerForm = ({
	container,
	setContainer,
	models,
	groups,
	flows,
	onSave,
	onUpdate,
	tags = false,
	onCancel
}: ContainerFormTypes) => {
	const [nextStep, setNextStep] = useState<'create-and-install'>();
	const [step, setStep] = useState<1 | 2>(1);
	const [hasDP, setHasDP] = useState(false);

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	const { organizationId } = useOriginCreateContainerContext();

	useEffect(() => {
		hasDepositPoints(organizationId)
			.then(setHasDP)
			.then(() => setLoading(false));
	}, [organizationId]);

	// We disabled this ESLint rule, because we want to be sure that the callback is executed only one time.
	/* eslint-disable react-hooks/exhaustive-deps */
	useLayoutEffect(() => {
		if (saving) {
			const save = onUpdate || onSave!;
			save(container, nextStep).then(() =>
				logger.log(`Container ${container.name} saved`)
			);
		}
	}, [saving]);

	const goNext = async () => {
		setSaving(true);
	};

	if (loading) {
		return <Loading />;
	}

	if (step === 1) {
		return (
			<ContainerFormStep1
				hasDP={hasDP}
				container={container}
				models={models}
				groups={groups}
				flows={flows}
				onSave={setContainer}
				onNext={() => setStep(2)}
				onCancel={onCancel}
				tags={tags}
			></ContainerFormStep1>
		);
	}

	return (
		<GenericGeolocationMap
			fromContainerForm={true}
			fromContainerUpdateForm={!!onUpdate}
			saving={saving}
			onKeepCoordinates={(c: FormContainer, step?: 'create-and-install') => {
				setNextStep(step);
				setContainer(c);
				goNext();
			}}
			onUpdateCoordinates={({ lat, lng }: { lat: number; lng: number }) => {
				setContainer({
					...container,
					latitude: lat,
					longitude: lng
				});
				goNext();
			}}
			container={container}
			onCancel={() => setStep(1)}
		></GenericGeolocationMap>
	);
};
